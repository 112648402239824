@use "sass:math";

.loadable-loader {
  position: relative;

  .spinner {
    position: absolute;
  }
}

.gallery-loader {
  min-height: calc(31vw + #{rem(100)});
}

.carousel-loader {
  min-height: rem(200);
}

.service-contact-loader {
  .spinner {
    transform: translate(-50%, -30%);
  }
}

.service-team-loader {
  height: 11rem;
}

.service-menu-loader {
  height: rem(113);
}

.vision-carousel-loader {
  height: 30rem;
  background: transparent linear-gradient(137deg, #077C74 0%, #266F95 0%, #17B2A6 100%) 0% 0% no-repeat padding-box;
}

.to-main-content {
  position: fixed;
  top: 0;
  left: 50%;
  z-index: 11;
  transform: translate(-50%, -100%);
  padding: rem(8) rem(20);
  border-radius: rem(0) rem(0) rem(4) rem(4);
  background: $color-cyan;
  font-size: rem(18);
  text-decoration: none;
  transition: transform 0.4s ease-in-out;

  @include hover {
    text-decoration: underline;
  }

  &:focus {
    transform: translate(-50%, 0%);
  }
}

.field-row {
  position: relative;
}

.widget {
  margin-bottom: rem(40);

  &:last-child {
    margin-bottom: 0;
  }

  @include page-break(1) {
    margin-bottom: rem(60);
  }

  @include page-break(2) {
    margin-bottom: rem(100);
  }
}

.blocks {
  padding-bottom: rem(100);

  @include page-break(2) {
    padding-bottom: rem(134);
  }
}

@import 'widgets/svg';
@import 'widgets/icons';
@import 'widgets/images';
@import 'widgets/menu-employee-overlay';
@import 'widgets/intro';
@import 'widgets/service';
@import 'widgets/reference';
@import 'widgets/headers';
@import 'widgets/gallery';
@import 'widgets/map';
@import 'widgets/location';
@import 'widgets/vimeo';
@import 'widgets/blockquote';
@import 'widgets/company';
@import 'widgets/image-group';
@import 'widgets/service-team';
@import 'widgets/badges';
@import 'widgets/entry';
@import 'widgets/project-header-image';
@import 'widgets/project-details';
@import 'widgets/managements';
@import 'widgets/person';
