@use "sass:math";

.reference-carousel {
  padding: rem(100) 0;
  background: #0d3d50;

  @include page-break(2) {
    padding: rem(122) 0;
  }

  header {
    h2,
    p {
      @include full-grid-columns(2, 10);
      color: white;
      text-align: center;
    }

    h2 {
      margin-bottom: math.div(20em, 50);
    }

    p {
      font-size: rem(16);
    }

    @include page-break(2) {
      p {
        font-size: rem(25);
      }
    }
  }

  footer {
    text-align: center;

    a {
      font-size: rem(16);
      color: white;

      @include page-break(2) {
        font-size: rem(math.div(16, 0.75));
      }

      @include page-break(5) {
        font-size: rem(16);
      }
    }
  }

  .services-block {
    margin-left: rem(-4);
    width: calc(100% + #{rem(8)});
    opacity: 0.6;

    .svg {
      margin-right: math.div(15em, 17);
      width: rem(17);

      * {
        &[stroke^='#'] {
          stroke: $color-base;
        }

        &[fill^='#'] {
          fill: $color-base;
        }
      }
    }

    .item {
      margin: 0 rem(4) rem(12);
      font-size: rem(12);
    }

    @include page-break(1) {
      margin-left: rem(-15);
      width: calc(100% + #{rem(30)});

      .svg {
        width: rem(28);
      }

      .item {
        margin: 0 rem(15) rem(15);
        font-size: rem(20);
      }

      .title {
        opacity: 1;
      }
    }
  }
}

.reference-slide {
  display: block;
  position: relative;
  width: 100%;
  color: white !important;
  text-align: center;
  overflow: hidden;

  .image {
    width: 100%;

    & > div {
      padding-top: math.div(230%, 2.88);

      @include page-break(1) {
        padding-top: math.div(600%, 10.88);
      }
    }
  }

  svg {
    pointer-events: none;
  }

  .text {
    $easing: all 0.8s $ease-out-cubic;

    position: absolute;
    left: 0;
    bottom: 0;
    padding: rem(20) rem(17);
    width: 100%;
    overflow: hidden;
    pointer-events: none;
    transition: $easing;

    background: transparent linear-gradient(180deg, #00000000 0%, #000000 100%)
      0% 0% no-repeat padding-box;

    & > div {
      overflow: hidden;
    }

    h3 {
      margin-bottom: math.div(10em, 30);
    }

    .svg-arrow {
      margin: 0 auto;
      padding: rem(20) 0 rem(40);
      width: rem(14);

      .b {
        stroke: $color-cyan;
      }
    }

    .intro {
      display: none;
      margin: 0 auto;
      max-height: 0;
      overflow: hidden;
      transition: $easing;
      opacity: 0.8;
      max-width: rem(540);

      p {
        font-size: rem(18);
      }

      & > div {
        padding-bottom: rem(40);
      }
    }

    @include page-break(0.5) {
      padding: rem(40);

      .intro {
        display: block;
      }
    }

    @include page-break(1) {
      padding: 10% rem(17) rem(20);
    }

    @include page-break(2.5) {
      padding: 10% rem(30) rem(30);
    }

    @include page-break(3) {
      padding: 10% rem(40) rem(40);

      .intro {
        p {
          font-size: rem(20);
        }
      }
    }
  }

  @include hover(true) {
    color: white;
  }

  .slide-2 & {
    @include hover(true) {
      $duration: 1.5s;

      .text {
        padding-top: 20%;
        transition-timing-function: $ease-out-quart;
        transition-duration: $duration;

        .intro {
          max-height: 20rem;
          transition-timing-function: $ease-out-quart;
          transition-duration: $duration;
        }
      }
    }
  }
}

