@use "sass:math";
@import 'utils/__utils';

.cookie-consent {
  position: fixed;
  left: 0;
  bottom: 0;
  z-index: 20;
  width: 100%;
  font-size: rem(12);
  background: $color-gray;
  box-shadow: 0px -2px 4px 0px rgba(0, 0, 0, 0.35);

  @include page-break(2) {
    font-size: rem(22);
  }

  @include page-break(5) {
    font-size: rem(14);
  }

  button {
    font-size: math.div(14em, 12);
    white-space: nowrap;
  }

  .accept {
    border: 1px solid $color-black;
    padding: math.div(5em, 14) math.div(10em, 14);
    min-width: math.div(90em, 14);

    &:hover {
      color: $color-white;
      background: $color-black;
    }
  }

  p {
    a {
      white-space: nowrap;
      text-decoration: underline;
    }
  }

  .close {
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: math.div(90em, 14);

    button {
      width: math.div(11em, 14);
    }
  }

  & > div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    padding: 2em;

    text-align: center;

    p {
      margin: 2em;
      font-size: 1em;
    }

    @include page-break(0.75) {
      margin: 0 auto;
      flex-direction: row;
      max-width: math.div(1024em, 14);

      p {
        margin: 0 2em;
      }
    }
  }

  &.enter {
    transform: translateY(100%);
  }

  &.enter-active {
    transition: transform 0.3s ease-in-out 0.4s;
    transform: translateY(0%);
  }

  &.exit {
    transform: translateY(0%);
  }

  &.exit-active {
    transition: transform 0.2s ease-in-out;
    transform: translateY(100%);
  }
}
