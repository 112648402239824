/*! Generated by Font Squirrel (https://www.fontsquirrel.com) on June 18, 2021 */

@font-face {
  font-family: 'poppins';
  src: url('~fonts/poppins/italic-webfont.woff2') format('woff2'),
    url('~fonts/poppins/italic-webfont.woff') format('woff');
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'poppins';
  src: url('~fonts/poppins/medium-webfont.woff2') format('woff2'),
    url('~fonts/poppins/medium-webfont.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'poppins';
  src: url('~fonts/poppins/mediumitalic-webfont.woff2') format('woff2'),
    url('~fonts/poppins/mediumitalic-webfont.woff') format('woff');
  font-weight: 500;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'poppins';
  src: url('~fonts/poppins/regular-webfont.woff2') format('woff2'),
    url('~fonts/poppins/regular-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'poppins';
  src: url('~fonts/poppins/semibold-webfont.woff2') format('woff2'),
    url('~fonts/poppins/semibold-webfont.woff') format('woff');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'poppins';
  src: url('~fonts/poppins/semibolditalic-webfont.woff2') format('woff2'),
    url('~fonts/poppins/semibolditalic-webfont.woff') format('woff');
  font-weight: 600;
  font-style: italic;
  font-display: swap;
}
