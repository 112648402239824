.widget-company {
  .card {
    padding: rem(40) rem(23);
    color: $color-white;
    background: transparent linear-gradient(343deg, #114c64 0%, #002a3b 100%) 0%
      0% no-repeat padding-box;

    .h3 {
      margin-bottom: rem(40);
    }

    .h4 {
      margin-bottom: rem(10);
      font-size: rem(12);
      font-weight: 500;
      opacity: 0.5;

      @include page-break(1) {
        font-size: rem(16);
      }

      @include page-break(2) {
        font-size: rem(22);
      }

      @include page-break(5) {
        font-size: rem(16);
      }
    }

    a {
      color: $color-white;
    }

    strong {
      display: block;
      margin-bottom: 0.5em;
      font-weight: normal;

      &:last-child {
        margin-bottom: 0;
      }

      & + br {
        display: none;
      }
    }

    .contact-methods {
      & > div {
        &:first-child {
          padding-bottom: rem(60);
          border-bottom: 1px solid rgba($color-white, 0.1);
          margin-bottom: rem(60);
        }
      }

      .visit,
      .invoice,
      .post,
      .phone {
        margin-bottom: rem(30);
      }

      p {
        @include page-break(2) {
          font-size: rem(26);
        }

        @include page-break(5) {
          font-size: rem(18);
        }
      }

      .link {
        @include page-break(2) {
          font-size: rem(26);
        }

        @include page-break(5) {
          font-size: rem(18);
        }
      }

      @include page-break(0.5) {
        & > div {
          @include grid;
          @include grid-template-columns(1fr 0.5rem 1fr);
          @include grid-template-rows(auto #{rem(30)} auto);

          @include page-break(2) {
            @include grid-template-columns(1fr 1rem 1fr);
          }
        }

        .visit,
        .invoice,
        .post,
        .phone {
          margin-bottom: 0;
        }

        .visit,
        .phone {
          @include grid-column(1);
          @include grid-row(1);
        }

        .visit {
          & ~ .phone {
            @include grid-row(3);
          }
        }

        .post,
        .email {
          @include grid-column(3);
          @include grid-row(1);
        }

        .post {
          & ~ .email {
            @include grid-row(3);
          }
        }

        .invoice,
        .electronic {
          @include grid-row(1);
        }

        .invoice {
          @include grid-column(1, 3);

          & ~ .electronic {
            @include grid-row(3);
          }

          p {
            @include grid;
            @include grid-template-columns(1fr 0.5rem 1fr);

            @include page-break(2) {
              @include grid-template-columns(1fr 1rem 1fr);
            }

            strong,
            span {
              display: block;
              @include grid-column(1);
            }

            strong {
              & + span {
                @include grid-column(3);
              }
            }
          }
        }

        .electronic {
          @include grid-column(1, 3);
        }
      }
    }
  }
}
