@use "sass:math";

:root {
  @include page-break(2) {
    font-size: ($root-font-size * 0.58);
  }

  @include page-break(5) {
    font-size: $root-font-size;
  }

  @include page-break(6) {
    font-size: 0.7vw;
  }
}

body {
  font: #{$root-font-size}/#{$root-line-height} #{$root-font-family};
  color: $color-font-base;
}

button,
input,
textarea {
  font-family: $root-font-family;
}

h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5,
h6,
.h6 {
  margin: 0;
}

h1,
.h1 {
  margin-bottom: math.div(20em, 50);
  @include font('h1');
}

h2,
.h2 {
  @include font('h2');
}

h3,
.h3 {
  @include font('h3');
}

h4,
.h4 {
  @include font('h4');
}

h5,
.h5 {
  @include font('h5');
}

h6,
.h6 {
  @include font('h6');
}

p {
  @include font('default');
}

a,
button {
  outline: none;

  &:focus {
    outline: 1px solid $color-blue-light;
    outline-offset: 0.2em;
  }
}

select,
input {
  outline: none;

  &:focus {
    box-shadow: inset 0 0 0 rem(2) $color-blue-light;
  }
}

a {
  color: $color-link;
  text-decoration: none;
  transition: color 200ms ease-out;

  &:hover {
    color: $color-link-hover;
  }
}

.link {
  @include link;
}

b,
strong {
  font-weight: 700;
}

i,
em {
  font-style: italic;
}

blockquote {
  margin: 0 0.5em;
  @include font('blockquote');

  p {
    display: inline;
    font-size: 1em;
    color: $color-blue-light;

    .line {
      display: inline-block !important;
      margin-bottom: rem(3);
      padding: 0.6em 0 0.5em;
      line-height: normal;
      background: $color-blue-dark;
      box-shadow: 0.5em 0 0 $color-blue-dark, -0.5em 0 0 $color-blue-dark;
    }
  }
}

figcaption {
  padding: 0.5em 0;
  font-size: rem(12);
  color: $color-black;
  opacity: 0.5;

  @include page-break(1) {
    font-size: rem(14);
  }

  @include page-break(2) {
    font-size: rem(18);
  }

  @include page-break(5) {
    font-size: rem(14);
  }
}

.intro {
  p {
    margin-bottom: 1em;
    @include font('intro');

    &:last-child {
      margin-bottom: 0;
    }

    a {
      text-decoration: underline;

      @include hover {
        color: $color-green-dark;
      }
    }
  }
}

.body {
  h2 {
    margin-bottom: 0.5em;
  }

  a {
    text-decoration: underline;

    &:hover {
      color: $color-green-dark;
    }
  }

  p {
    margin-bottom: 1em;
    @include font('body');

    &:last-child {
      margin-bottom: 0;
    }
  }

  li {
    margin-left: rem(6);
    padding-bottom: 0.5em;
    padding-left: rem(10);

    @include font('li');

    &::marker {
      color: $color-green-dark;
    }

    .line {
      display: inline-block !important;
      background: $color-gray;
      box-shadow: 0.5em 0 0 $color-gray, -0.5em 0 0 $color-gray;
    }
  }

  ul,
  ol {
    padding-top: rem(6);
    padding-left: rem(14);

    &:first-child {
      padding-top: 0;
    }
  }
}

.submenu .sub,
.submenu .main,
.main-footer {
  a {
    @include link;
  }
}
