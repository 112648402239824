@use "sass:math";

.widget-intro {
  p {
    font-size: rem(20);
    line-height: math.div(30, 20);

    @include page-break(2) {
      font-size: rem(34);
    }

    @include page-break(5) {
      font-size: rem(25);
    }
  }
}
