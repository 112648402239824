@use "sass:math";

.widget-entry {
  @include grid-template-rows(
    #{rem(70)} #{rem(30)} auto #{rem(30)} #{rem(30)} auto #{rem(65)}
  );

  h2 {
    margin-bottom: math.div(10em, 30);
    line-height: math.div(40, 30);
  }

  .body {
    margin-bottom: rem(20);

    p {
      font-size: rem(16);
    }
  }

  .link {
    margin-left: rem(24);
    color: $color-black;

    &:before {
      content: '';
      display: block;
      position: absolute;
      top: 50%;
      right: calc(100% + #{rem(10)});
      width: rem(14);
      height: rem(14);
      background: url(~gfx/arrow-right-green.svg) center center no-repeat;
      background-size: 100% 100%;
      transform: translate(0, -50%);
    }

    &::after {
      background-color: $color-green-medium;
    }
  }

  &:before {
    content: '';
    display: block;
    @include grid-column(2, 22);
    @include grid-row(2, 2);

    background: transparent linear-gradient(238deg, #0aafa2 0%, #1a7e9400 100%)
      0% 0% no-repeat padding-box;

    transform: translate(1.5rem, 1.5rem);

    @include page-break(2) {
      transform: translate(2rem, 2rem);
    }
  }

  .decor {
    @include grid-row(1, 7);
    position: relative;
    width: 100%;
    height: 100%;
    @include grid-row-align(center);

    canvas {
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }

  .image {
    @include grid-column(2, 22);
    @include grid-row(2, 2);

    & > div {
      box-sizing: border-box;
      padding-top: math.div(447%, 7.04);
      height: 100%;
    }
  }

  .text {
    @include full-grid-columns(1, 12);
    @include grid-row(6);
    opacity: 0.8;
  }

  @include page-break(1) {
    @include grid-template-rows(
      #{rem(200)} #{rem(30)} auto #{rem(30)} #{rem(200)}
    );

    &:before {
      @include full-grid-columns(1, 6);
      @include grid-row(2, 2);
    }

    h2 {
      font-size: rem(30);
    }

    .decor {
      @include grid-row(2, 3);
    }

    .image {
      @include full-grid-columns(1, 6);
      @include grid-row(2, 2);
    }

    .text {
      @include full-grid-columns(8, 5);
      @include grid-row(3);
      @include grid-row-align(center);
    }
  }

  @include page-break(2) {
    h2 {
      font-size: rem(50);
    }

    .body {
      p {
        font-size: rem(20);
      }
    }

    &:before {
      transform: translate(3rem, 3rem);
    }

    .link {
      font-size: rem(21);
    }
  }

  @include page-break(3) {
    .body {
      margin-bottom: rem(33);

      p {
        font-size: rem(25);
      }
    }

    .link {
      font-size: rem(20);
    }
  }
}
