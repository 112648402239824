@use "sass:math";

.widget-location {
  @include grid-template-rows(auto auto);

  h2 {
    padding-bottom: rem(80);
  }

  h3 {
    margin-bottom: 0.5em;
    font-weight: 500;
    opacity: 0.6;

    @include page-break(2) {
      font-size: rem(16);
    }
  }

  .background,
  .text {
    @include grid-row(1);
  }

  .background {
    @include full-grid-columns(1, 12);
    background: transparent linear-gradient(128deg, #1d6e8e 0%, #002a3b 100%) 0%
      0% no-repeat padding-box;
  }

  .text {
    @include full-grid-columns(2, 10);
    padding-top: rem(30);
    padding-bottom: rem(30);
    color: $color-base;
  }

  map-component {
    display: block;
    @include grid-row(2);
    @include full-grid-columns(1, 12);
    height: rem(260);
  }

  .map-container {
    height: 100%;
  }

  @include page-break(1) {
    min-height: 60vh;
    @include grid-template-rows(auto);

    h2 {
      flex: 1 1 auto;
    }

    .background {
      @include grid-column(2, 10);
    }

    .text {
      @include full-grid-columns(2, 4);
      display: flex;
      flex-direction: column;
      padding-top: rem(60);
      padding-bottom: rem(60);
    }

    map-component {
      @include grid-row(1);
      @include full-grid-columns(6, 7);
      height: auto;
    }
  }

  @include page-break(2) {
    map-component {
      @include full-grid-columns(6, 6);
    }

    .background {
      display: none;
      @include grid-column(4, 8);
    }

    .text {
      @include grid-column(4, 8);
      padding: rem(60);
      background: transparent linear-gradient(128deg, #1d6e8e 0%, #002a3b 100%) 0%
      0% no-repeat padding-box;
    }
  }

  @include page-break(3) {
    map-component {
      @include full-grid-columns(6, 5);
    }

    .text {
      @include grid-column(6, 6);
    }
  }
}
