@use "sass:math";

* {
  margin: 0;
  padding: 0;
}

html {
  height: 100%;
}

body {
  position: relative;
  @include grid;
  @include grid-template-columns(auto);
  @include grid-template-rows(auto auto);
  min-height: 100%;
  background-color: $color-bg;
}

main-nav-component,
main {
  @include grid-row(1);
  @include grid-column(1);
}

.main-footer {
  @include grid-row(2);
  @include grid-column(1);
}

#fullscreen-gallery {
  @include grid-row(1, 2);
  @include grid-column(1);
}

canvas {
  display: block;
  width: 100%;
}

.sr-only:not(:focus):not(:active) {
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  top: -1px;
  left: -1px;
  white-space: nowrap;
  width: 1px;
}

/*
Put the tweakpane config element in the bottom right corner
*/
.tp-rotv {
  position: absolute;
  bottom: 10px;
  right: 10px;
  max-width: 300px;
}

input,
select {
  border: none;
  width: 100%;
  padding: rem(20) rem(20);
  background: transparent;

  @include page-break(1) {
    padding: rem(20) rem(30);
    font-size: rem(20);
  }
}

input {
  font-size: rem(16);
  color: $color-white;

  @include page-break(2) {
    font-size: rem(24);
  }

  @include page-break(5) {
    font-size: rem(20);
  }
}

select {
  padding-right: rem(44);
  appearance: none;
  font-size: rem(14);
  color: $color-white;
  background-image: url(~gfx/chevron-down.svg);
  background-repeat: no-repeat;
  background-position: right rem(20) center;
  opacity: 0.6;

  &::-ms-expand {
    display: none;
  }

  &:focus {
    opacity: 1;

    option {
      color: $color-black;
    }
  }

  @include page-break(1) {
    padding-right: rem(54);
  }

  @include page-break(2) {
    font-size: rem(24);
  }

  @include page-break(5) {
    font-size: rem(20);
  }
}

.react-select {
  .default__menu-list {
    padding: 0;
    color: $color-white;
    background-color: $color-blue-medium;
    background: red;

    &::-webkit-scrollbar {
      width: rem(10);
    }

    &::-webkit-scrollbar-track {
      box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    }

    &::-webkit-scrollbar-thumb {
      background-color: $color-blue-light;
      outline: 1px solid slategrey;
    }
  }

  .default__indicator-separator {
    display: none;
  }

  .default__control {
    border: none;
    border-radius: 0;
    min-height: rem(55.6);
    font-size: rem(14);
    background: none;
  }

  .default__value-container {
    padding: 0 rem(20);
  }

  .default__indicator {
    padding-right: rem(30);

    .chevron {
      stroke: $color-white;
      opacity: 0.6;
    }
  }

  .default__placeholder,
  .default__single-value {
    color: $color-white;
    opacity: 0.6;
  }

  .default__control--is-focused {
    box-shadow: inset 0 0 0 rem(2) $color-blue-light;

    .default__placeholder,
    .default__single-value {
      opacity: 1;
    }

    .default__indicator {
      .chevron {
        opacity: 1;
        stroke: $color-cyan;
      }
    }
  }

  .default__option {
    background-color: $color-blue-medium;
    transition: all 0.2s ease-in-out;
  }

  .default__option--is-focused {
    background-color: lighten($color-blue-light, 10);
    color: $color-black;
  }

  .default__option--is-selected {
    color: $color-white;
    background-color: $color-blue-dark;
  }

  @include page-break(2) {
    .default__control {
      min-height: rem(61.5);
      font-size: rem(22);
    }

    .default__option {
      font-size: rem(22);
    }
  }

  @include page-break(5) {
    .default__control {
      min-height: rem(60);
      font-size: rem(18);
    }

    .default__option {
      font-size: rem(18);
    }
  }
}

button {
  margin: 0;
  padding: 0;
  border: none;
  background: none;
  cursor: pointer;
}

img {
  display: block;
  width: 100%;
  height: auto;
}

main {
  position: relative;
  z-index: 2;
}

.main-footer {
  position: relative;
  z-index: 1;
  padding-top: rem(72);
  color: $color-base;
  background: $gradient-footer;
  @include grid-template-rows(auto auto auto auto auto);

  h2,
  h3 {
    margin-bottom: rem(10);
    font-size: rem(12);
    font-weight: 500;
    opacity: 0.8;
  }

  .name {
    font-size: rem(20);
  }

  .header {
    margin-bottom: rem(40);
    @include grid-row(1);
    @include full-grid-columns(1, 12);
  }

  .visiting-address {
    @include grid-row(2);
    @include full-grid-columns(1, 5);
  }

  .post-address {
    @include grid-row(2);
    @include full-grid-columns(7, 5);
  }

  .phone,
  .email {
    @include grid-row(3);
    padding-top: rem(30);
    padding-bottom: rem(60);
  }

  .phone {
    @include full-grid-columns(1, 5);
  }

  .email {
    @include full-grid-columns(7, 5);
    white-space: nowrap;
  }

  .footer-menu {
    display: none;
  }

  .extra {
    @include grid-row(4);
    @include full-grid-columns(1, 6);

    a {
      .no-touch & {
        opacity: 0.6;
        transition: opacity 0.2s ease-in-out;

        &:hover {
          opacity: 1;
        }
      }
    }

    div {
      margin-bottom: rem(15);
      font-size: rem(12);

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  hint-component {
    display: block;
    @include grid-row(4);
    @include full-grid-columns(8, 5);
    @include grid-row-align(end);

    .text {
      .no-touch & {
        opacity: 0.6;
        transition: opacity 0.2s ease-in-out;

        &:hover {
          opacity: 1;
        }
      }
    }
  }

  canvas {
    @include grid-row(5);
    height: rem(270);
  }

  @include page-break(1) {
    .post-address,
    .email {
      @include full-grid-columns(8, 5);
    }

    .main {
      a {
        margin-bottom: math.div(20em, 30);
        font-size: rem(20);
        font-weight: 600;
        line-height: math.div(40, 30);
      }
    }

    .sub {
      a {
        margin-bottom: 1em;
        font-size: rem(20);
        font-size: rem(16);
      }
    }

    .extra {
      @include full-grid-columns(1, 5);

      div {
        &:last-child {
          margin-bottom: rem(15);
        }
      }
    }
  }

  @include page-break(2) {
    @include grid-template-rows(auto auto auto auto 160px auto auto);
    padding-top: rem(140);
    font-size: rem(20);

    h2,
    h3 {
      font-size: rem(20);
    }

    .name {
      font-size: rem(34);
    }

    .header {
      @include full-grid-columns(1, 5);

      h2 {
        margin-bottom: rem(38);
      }
    }

    .email,
    .phone {
      padding-bottom: 0;
    }

    .visiting-address,
    .phone {
      @include full-grid-columns(1, 3);
    }

    .post-address,
    .email {
      @include full-grid-columns(4, 3);
    }

    .footer-menu {
      @include grid-row(1, 3);
      @include full-grid-columns(10, 3);
      display: flex;
      flex-direction: column;

      h3 {
        margin-bottom: rem(38);
      }
    }

    .main {
      flex: 1 1 auto;
      margin-bottom: rem(60);

      a {
        font-size: rem(34);
      }
    }

    .sub {
      a {
        font-size: rem(24);
      }
    }

    .extra {
      margin-bottom: rem(-15);
      @include full-grid-columns(1, 6);
      @include grid-row(6);
      @include grid;
      @include grid-template-columns(repeat(5, 1fr 1rem) 1fr);

      a {
        font-size: rem(20);
      }

      div {
        @include grid-column(1, 5);

        &:nth-child(even) {
          @include grid-column(7, 5);
        }
      }
    }

    hint-component {
      @include grid-row(6);
      @include full-grid-columns(10, 3);

      button {
        font-size: rem(20);
      }
    }

    canvas {
      @include grid-row(7);
    }
  }

  @include page-break(3) {
    .header,
    .extra {
      @include full-grid-columns(1, 4);
    }

    .visiting-address,
    .phone {
      @include full-grid-columns(1, 2);
    }

    .post-address,
    .email {
      @include full-grid-columns(3, 2);
    }

    .extra {
      @include grid-template-columns(repeat(3, 1fr 1rem) 1fr);

      div {
        @include grid-column(1, 3);

        &:nth-child(even) {
          @include grid-column(5, 3);
        }
      }
    }

    .footer-menu,
    hint-component {
      @include full-grid-columns(11, 2);
    }
  }

  @include page-break(5) {
    h2,
    h3 {
      font-size: rem(16);
    }

    .name {
      font-size: rem(30);
    }

    .main {
      a {
        font-size: rem(30);
      }
    }

    .sub {
      a {
        font-size: rem(20);
      }
    }

    .extra {
      a {
        font-size: rem(16);
      }
    }

    hint-component {
      button {
        font-size: rem(16);
      }
    }
  }
}
