@use "sass:math";

.widget-image-group {
  @include grid-template-rows(1.3fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr);
  padding-top: rem(20);
  margin-bottom: rem(60);

  .decor {
    display: block;
    @include grid-column(5, 17);
    @include grid-row(3, 6);
    background: transparent
      linear-gradient(239deg, #0aafa2 0%, #19829517 52%, #1a7e9400 100%) 0% 0%
      no-repeat padding-box;
  }

  .image {
    & > div {
      box-sizing: border-box;
      height: 100%;
    }

    &:nth-child(2) {
      @include grid-column(2, 12);
      @include grid-row(2, 5);

      & > div {
        padding-top: math.div(120%, 1.7);
      }
    }

    &:nth-child(3) {
      @include full-grid-columns(8, 5);
      @include grid-row(1, 7);

      & > div {
        padding-top: math.div(180%, 1.3);
      }
    }
  }

  @include page-break(1) {
    @include grid-template-rows(1fr 1fr 1fr 1fr 1fr 1fr 1fr);
    padding-top: rem(40);
    margin-bottom: rem(134);

    .decor {
      @include full-grid-columns(3, 8);
      @include grid-row(3, 5);
      @include grid-column-align(center);
      width: 86%;
    }

    .image {
      &:nth-child(2) {
        @include full-grid-columns(2, 4);
        @include grid-row(2, 4);
      }

      &:nth-child(3) {
        @include full-grid-columns(7, 5);
        @include grid-row(1, 6);
      }
    }
  }

  @include page-break(4) {
    .decor {
      @include full-grid-columns(4, 6);
    }

    .image {
      &:nth-child(2) {
        @include full-grid-columns(3, 4);
      }

      &:nth-child(3) {
        @include full-grid-columns(8, 3);
      }
    }
  }
}
