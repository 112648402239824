.contact-entry {
  .page-header {
    margin-bottom: rem(60);
  }

  contact-map-component {
    display: block;
    margin-bottom: rem(60);

    @include page-break(2) {
      padding-bottom: rem(100);
    }
  }

  .managements {
    padding-bottom: rem(100);

    @include page-break(2) {
      padding-botbackground: transparent
        linear-gradient(238deg, #a3ecf2 0%, #1a7e9400 100%) 0% 0% no-repeat
        padding-box;
      tom: rem(160);
    }
  }
}
