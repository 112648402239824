@use "sass:math";

.project-header-image {
  & div {
    box-sizing: border-box;
    padding-top: math.div(270%, 3.75);

    @include page-break(1) {
      padding-top: math.div(840%, 19.2);
    }

    @include page-break(2) {
      padding-top: 0;
      height: 80vh;
    }
  }
}
