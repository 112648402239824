@use "sass:math";

.person {
  font-size: rem(12);
  color: $color-white;

  a {
    color: $color-white;
  }

  .title {
    display: block;
    margin-bottom: 1em;
  }

  .image {
    margin-bottom: rem(20);
  }

  .name {
    margin-bottom: math.div(10em, 16);
    font-size: rem(16);
    font-weight: 400;
  }

  .role {
    color: $color-cyan;
  }

  .company {
    margin-bottom: rem(10);
    color: $color-blue-light;
  }

  .phone {
    margin-bottom: rem(5);
  }

  @include page-break(1) {
    .name {
      font-size: rem(18);
    }

    .phone,
    .email {
      a {
        text-decoration: none;
        @include link;
      }
    }
  }

  @include page-break(2) {
    .name {
      font-size: rem(30);
    }

    .phone,
    .email,
    .role,
    .company {
      font-size: rem(20);
    }
  }

  @include page-break(5) {
    .name {
      font-size: rem(25);
    }

    .phone,
    .email,
    .role,
    .company {
      font-size: rem(14);
    }
  }
}

.employees {
  .filter {
    @include grid;
    @include grid-template-columns(1fr 0.5rem 1fr);
    @include grid-template-rows(auto auto auto);
    position: relative;
    z-index: 3;

    margin-bottom: rem(35);

    .management {
      @include grid-row(1);
      @include grid-column(1, 3);

      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      margin-bottom: rem(33);

      .label,
      button {
        font-size: rem(12);
        line-height: normal;
      }

      .label {
        margin-right: math.div(15em, 12);
        color: $color-blue-light;
      }

      button {
        appearance: none;
        display: block;
        margin-right: math.div(15em, 12);

        color: $color-base;
        opacity: 0.6;
        transition: opacity 0.2s ease-in-out;
        outline: none;

        @include hover {
          opacity: 1;
        }

        &:last-child {
          margin-right: 0;
        }

        span {
          @include link;
          padding-bottom: 0.5em;
        }

        &.active,
        &:focus {
          opacity: 1;

          span {
            &::after {
              width: 100% !important;
            }
          }
        }
      }
    }

    .field-row {
      background: $color-blue-medium;
    }

    .field-search {
      @include grid-row(2);
      @include grid-column(1, 3);

      margin-bottom: rem(20);
      background: #204d5f;

      .svg {
        position: absolute;
        top: 50%;
        right: rem(30);
        transform: translate(0, -50%);
        width: rem(18);
        opacity: 0.5;

        path {
          stroke: $color-white;
        }
      }
    }

    .field-service,
    .field-company {
      @include grid-row(3);
    }

    .field-service {
      @include grid-column(1);
    }

    .field-company {
      @include grid-column(3);
    }

    @include page-break(1) {
      @include grid-template-columns(1fr 0.5rem 1fr 0.5rem 1fr);
      @include grid-template-rows(auto auto);

      .management {
        @include grid-column(1, 5);

        .label,
        button {
          font-size: rem(16);

          @include page-break(2) {
            font-size: rem(22);
          }

          @include page-break(5) {
            font-size: rem(18);
          }
        }
      }

      .field-search {
        margin-bottom: 0;
        @include grid-column(1);
      }

      .field-service,
      .field-company {
        @include grid-row(2);
      }

      .field-service {
        @include grid-column(3);
      }

      .field-company {
        @include grid-column(5);
      }
    }

    @include page-break(2) {
      @include grid-template-columns(1fr 1rem 1fr 1rem 1fr);
      margin-bottom: rem(35);

      .management {
        margin-bottom: rem(60);
      }
    }
  }

  .person {
    margin-bottom: rem(40);
    width: 100%;
  }

  .persons {
    min-height: 40vh;
  }

  .nomatch {
    width: 100%;
    font-size: rem(20);
    color: $color-white;
  }

  .more {
    display: flex;
    justify-content: center;
    padding-top: rem(20);

    button {
      font-size: 1em;
      color: $color-white;

      & > span {
        display: flex;
        justify-content: flex-start;
        align-items: center;
      }

      .svg {
        margin-right: math.div(14em, 16);
        min-width: math.div(14em, 16);
        width: math.div(14em, 16);
      }

      @include page-break(2) {
        font-size: math.div(1em, 0.58);
      }

      @include page-break(5) {
        font-size: 1em;
      }
    }
  }

  @include page-break(0.5) {
    .person {
      width: calc(50% - 0.25rem);
    }

    .persons {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      flex-wrap: wrap;
    }
  }

  @include page-break(1) {
    .persons {
      justify-content: flex-start;
    }

    .person {
      margin-right: 0.5rem;
      width: calc(#{math.div(100%, 3)} - #{math.div(1rem, 3)});

      &:nth-child(3n) {
        margin-right: 0;
      }
    }
  }

  @include page-break(2) {
    .person {
      margin-right: 1rem;
      margin-bottom: rem(60);
      width: calc(#{math.div(100%, 3)} - #{math.div(2rem, 3)});
    }
  }
}

.employee-overlay {
  padding-bottom: rem(100);

  & > div {
    @include grid;
    @include grid-template-columns(rem(23) 1fr 0.5rem 1fr rem(23));
    @include grid-template-rows(auto auto auto);
  }

  .top {
    @include grid-column(1, 5);
    @include grid-row(1);
    margin-bottom: rem(55);
  }

  .heading {
    @include grid-column(2, 3);
    @include grid-row(2);
    margin-bottom: rem(40);

    color: $color-base;

    h2 {
      font-size: rem(30);
    }

    p {
      padding-top: 1em;
      max-width: rem(680);
    }
  }

  .employees {
    @include grid-column(2, 3);
    @include grid-row(3);
  }

  @include page-break(2) {
    padding-bottom: rem(246);

    & > div {
      // @include grid-template-columns(rem(70) 1fr 1rem 1fr rem(70));
      @include grid-template-columns(rem(140) 1fr 0.5rem 1fr rem(140));
    }

    .top {
      @include grid-column(2, 3);
      padding-left: 0;
      padding-right: 0;

      .home,
      .menu-toggle {
        display: none;
      }
    }
  }

  // @include page-break(3) {
  //   & > div {
  //     @include grid-template-columns(rem(100) 1fr 0.5rem 1fr rem(100));
  //   }
  // }

  // @include page-break(2) {
  //   & > div {
  //     @include grid-template-columns(rem(140) 1fr 0.5rem 1fr rem(140));
  //   }
  // }
}

.employee-index {
  background: $color-blue-dark;

  h2 {
    font-size: rem(30);
  }

  .section-header {
    background: $color-blue-dark;
  }

  main {
    padding-bottom: rem(100);

    h1 {
      color: $color-blue-light;
    }

    .intro {
      color: $color-base;
      opacity: 0.8;
    }

    header {
      position: relative;
      margin-bottom: rem(-100);
      z-index: 0;
    }

    .content {
      position: relative;
      z-index: 1;
    }
  }

  @include page-break(2) {
    main {
      header {
        position: relative;
        margin-bottom: rem(-200);
        z-index: 0;
      }

      .filter {
        @include grid-template-columns(1fr 1rem 1fr 1rem 1fr 1rem 1fr);

        .field-search {
          @include grid-column(1, 3);
        }

        .field-service {
          @include grid-column(5);
        }

        .field-company {
          @include grid-column(7);
        }
      }

      .person {
        width: calc(#{math.div(100%, 4)} - #{math.div(3rem, 4)});

        &:nth-child(3n) {
          margin-right: 1rem;
        }

        &:nth-child(4n) {
          margin-right: 0;
        }
      }
    }
  }

  @include page-break(4) {
    main {
      .filter {
        @include grid-template-columns(
          1fr 1rem 1fr 1rem 1fr 1rem 1fr 1rem 1fr 1rem 1fr
        );

        .field-service {
          @include grid-column(9);
        }

        .field-company {
          @include grid-column(11);
        }
      }

      .person {
        width: calc(#{math.div(100%, 6)} - #{math.div(5rem, 6)});

        &:nth-child(4n) {
          margin-right: 1rem;
        }

        &:nth-child(6n) {
          margin-right: 0;
        }
      }
    }
  }
}
