service-team-component {
  display: block;
  position: relative;
  min-height: rem(200);
  background: transparent linear-gradient(198deg, #6EBCC3 0%, #0D3D50 0%, #14887F 100%) 0% 0% no-repeat padding-box;

  .spinner {
    position: absolute;
  }
}
