@import 'utils/__utils';

vision-carousel-component {
  display: block;
}

.hint {
  color: $color-white;
  font-size: rem(12);

  .svg {
    margin-right: rem(14);
    min-width: rem(14);
    width: rem(14);
  }

  & > div {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
}
