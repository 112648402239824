.managements {
  .department {
    &:last-child {
      .person {
        &:last-child {
          margin-bottom: 0;
        }

        @include page-break(0.5) {
          &:nth-last-child(-n+2) {
            margin-bottom: 0;
          }
        }

        @include page-break(2) {
          &:nth-last-child(-n+3) {
            margin-bottom: 0;
          }
        }
      }
    }
  }

  h3,
  .persons {
    @include grid-column(1, 19);
  }

  h3 {
    @include grid-row(1);
    margin-bottom: 1em;

    @include page-break(2) {
      @include grid-column(1, 5);
    }
  }

  .person {
    margin-bottom: rem(60);
    color: $color-black;

    @include page-break(2) {
      margin-bottom: rem(85);
    }

    .role,
    .company,
    a {
      color: $color-black !important;
    }

    .link {
      &:after {
        background: $color-green-dark;
      }
    }

    @include page-break(0.5) {
      &:nth-child(1n) {
        @include grid-column(1, 9);
      }

      &:nth-child(2n) {
        @include grid-column(11, 9);
      }
    }

    @include page-break(2) {
      &:nth-child(3n - 2) {
        @include grid-column(1, 3);
      }

      &:nth-child(3n - 1) {
        @include grid-column(5, 3);
      }

      &:nth-child(3n) {
        @include grid-column(9, 3);
      }
    }
  }

  .persons {
    @include page-break(0.5) {
      @include grid;
      @include grid-template-columns(repeat(9, 1fr 0.5rem) 1fr);
      @include grid-row(2);
    }

    @include page-break(2) {
      @include grid-template-columns(repeat(6, 1fr 1rem) 1fr);
      @include grid-row(1);
      @include grid-column(7, 13);
    }
  }
}
