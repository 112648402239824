@use "sass:math";
@import 'utils/__utils';

.svg-hamburger {
  .a {
    fill: none;
    stroke: $color-cyan;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-width: 2.5px;
  }

  &>span {
    padding-top: math.div(1603.5%, 22.803);
  }
}
