// Fonts -----------------------------------------------------------------------
$root-font-family: 'poppins', Arial, sans-serif;
$root-font-size: 100%;
$root-line-height: 1.5;
$root-font-weight: 400;

// Colors ----------------------------------------------------------------------
$color-white: #fff;
$color-black: #000;
$color-gray: #f4f4f4;
$color-gray-medium: #ccc;
$color-blue-dark: #0d3d50;
$color-blue-medium: #0b6b9d;
$color-blue-light: #a3ecf2;
$color-blue-gray: #9eb5be;
$color-green-dark: #07897f;
$color-green-medium: #17b2a6;
$color-cyan: #45ff00;

$color-base: $color-white;
$color-bg: $color-white;

$color-font-base: $color-black;

// $color-error: $color-accent-tertiary;

$color-link: $color-black;
$color-link-hover: $color-black;

// Gradients
$gradient-main: transparent linear-gradient(36deg, #114C64 0%, #002A3B 100%) 0% 0% no-repeat padding-box;
$gradient-footer: transparent linear-gradient(299deg, #6EBCC3 0%, #00437C 0%, #266F95 38%, #009F92 100%) 0% 0% no-repeat padding-box;
$gradient-references: transparent linear-gradient(240deg, #077C74 0%, #266F95 0%, #17B2A6 100%) 0% 0% no-repeat padding-box;
$gradient-home-top: transparent linear-gradient(119deg, #6EBCC3 0%, #00437C 0%, #266F95 38%, #17B2A6 100%) 0% 0% no-repeat padding-box;
$gradient-vision: transparent linear-gradient(130deg, #077C74 0%, #266F95 0%, #17B2A6 100%) 0% 0% no-repeat padding-box;
$gradient-services: transparent linear-gradient(116deg, #1D6E8E 0%, #002A3B 100%) 0% 0% no-repeat padding-box;
$gradient-image: transparent linear-gradient(238deg, #0AAFA2 0%, #1A7E9400 100%) 0% 0% no-repeat padding-box;
$gradient-gallery: transparent linear-gradient(241deg, #077C74 0%, #266F95 0%, #17B2A6 100%) 0% 0% no-repeat padding-box;

// Meassurements ---------------------------------------------------------------
$page-width: 30em;
$break025: 375px;
$break05: 550px;
$break075: 650px;
$break1: 760px;
// $break12: 768px;
$break15: 890px;
$break2: 1024px;
$break25: 1100px;
$break3: 1250px;
$break4: 1440px;
$break5: 1920px;
$break6: 2200px;


// Easing effects
$ease-in-sine: cubic-bezier(0.47, 0, 0.745, 0.715);
$ease-out-sine: cubic-bezier(0.39, 0.575, 0.565, 1);
$ease-in-out-sine: cubic-bezier(0.445, 0.05, 0.55, 0.95);

$ease-in-quad: cubic-bezier(0.55, 0.085, 0.68, 0.53);
$ease-out-quad: cubic-bezier(0.25, 0.46, 0.45, 0.94);
$ease-in-out-quad: cubic-bezier(0.455, 0.03, 0.515, 0.955);

$ease-in-cubic: cubic-bezier(0.55, 0.055, 0.675, 0.19);
$ease-out-cubic: cubic-bezier(0.215, 0.61, 0.355, 1);
$ease-in-out-cubic: cubic-bezier(0.645, 0.045, 0.355, 1);

$ease-in-quart: cubic-bezier(0.895, 0.03, 0.685, 0.22);
$ease-out-quart: cubic-bezier(0.165, 0.84, 0.44, 1);
$ease-in-out-quart: cubic-bezier(0.77, 0, 0.175, 1);

$ease-in-quint: cubic-bezier(0.755, 0.05, 0.855, 0.06);
$ease-out-quint: cubic-bezier(0.23, 1, 0.32, 1);
$ease-in-out-quint: cubic-bezier(0.86, 0, 0.07, 1);

$ease-in-expo: cubic-bezier(0.95, 0.05, 0.795, 0.035);
$ease-out-expo: cubic-bezier(0.19, 1, 0.22, 1);
$ease-in-out-expo: cubic-bezier(1, 0, 0, 1);

$ease-in-circ: cubic-bezier(0.6, 0.04, 0.98, 0.335);
$ease-out-circ: cubic-bezier(0.075, 0.82, 0.165, 1);
$ease-in-out-circ: cubic-bezier(0.785, 0.135, 0.15, 0.86);

$ease-in-back: cubic-bezier(0.6, -0.28, 0.735, 0.045);
$ease-out-back: cubic-bezier(0.175, 0.885, 0.32, 1.275);
$ease-in-out-back: cubic-bezier(0.68, -0.55, 0.265, 1.55);

$ease-in-out-fast: cubic-bezier(1, 0, 0, 1);
