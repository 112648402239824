.svg-service-icon {
  path,
  line,
  circle,
  rect {
    transition: all 0.2s ease-in-out;
  }
  @include service-icon($color-blue-gray, $color-blue-light);
}

.svg-service-icon-green {
  @include service-icon($color-gray-medium, $color-green-medium);
}

.svg-service-icon-cyan {
  @include service-icon($color-gray-medium, $color-cyan);
}

.svg-service-icon-black {
  @include service-icon($color-black, $color-black);
}

a {
  @include hover() {
    & > .svg-service-icon {
      @include service-icon($color-base, $color-cyan);
    }
  }
}
