@use "sass:math";

.svg {
  display: block;

  & > span {
    display: block;
    position: relative;
    width: 100%;
    height: 0;

    svg {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 100%;
      overflow: visible;
    }
  }
}

.svg-square {
  & > span {
    padding-top: 100%;
  }
}

.svg-exit {
  & > span {
    padding-top: math.div(1713.8%, 16.635);
  }
}

.svg-user {
  & > span {
    padding-top: math.div(2365.8%, 21.307);
  }
}

.svg-logo {
  & > span {
    padding-top: math.div(4345.9%, 259.158);
  }
}

.svg-arrow-up,
.svg-arrow-down {
  & > span {
    padding-top: math.div(1600%, 16.828);
  }

  .a {
    fill: none;
    stroke: #45ff00;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-width: 2px;
  }
}

.svg-arrow-up {
  & > span {
    transform-origin: center;
    transform: rotate(180deg);
  }
}

.svg-arrow-left,
.svg-arrow-right {
  & > span {
    padding-top: math.div(3823.7%, 36.58);
  }

  .b {
    fill: none;
    stroke: #fff;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-width: 4px;
  }
}

.svg-arrow-right {
  & > span {
    transform: rotate(180deg);
  }
}

.svg-industri {
  & > span {
    padding-top: math.div(5331%, 41.98);
  }
}
