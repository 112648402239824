@use "sass:math";

.project-index {
  main {
    padding-bottom: rem(70);
  }

  .section-header {
    margin-bottom: -35vw;

    @include page-break(1) {
      margin-bottom: -10vw;
    }
  }

  .project-entries {
    & > div {
      @include page-break(2) {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
      }
    }

    .image {
      & > div {
        box-sizing: border-box;
        padding-top: math.div(220%, 3.28);
        height: 100%;

        @include page-break(1) {
          padding-top: math.div(438%, 7.6);
        }
      }
    }

    .entry {
      padding-bottom: rem(30);
      margin-bottom: rem(30);
      text-align: center;

      .image,
      .text,
      .services-block,
      &:after {
        @include grid-column(1, 23);
      }

      .intro {
        display: none;
        opacity: 0.8;

        p {
          font-size: rem(20);
        }
      }

      .svg-arrow {
        display: none;
        margin: 0 auto;
        padding: rem(20) 0 rem(40);
        width: rem(14);

        .b {
          stroke: $color-cyan;
        }
      }

      .svg-service-icon {
        margin-right: rem(10);
        width: rem(20);
      }

      .services-block {
        opacity: 0.8;

        .item {
          margin: 0 rem(10) rem(8);

          .title {
            font-size: rem(12);
            opacity: 1;
          }
        }
      }

      @include page-break(1) {
        @include grid-template-rows(auto auto auto);

        h2 {
          font-size: rem(40);
        }

        .svg-arrow {
          display: block;
        }

        .services-block {
          padding-bottom: rem(30);
          padding-left: rem(30);
          padding-right: rem(30);
          z-index: 2;

          .item {
            margin: 0 rem(14) rem(14);

            .title {
              font-size: rem(14);
            }
          }
        }

        .svg-service-icon {
          @include service-icon($color-white, $color-white);
        }

        .text,
        .services-block {
          @include grid-row-align(flex-end);
          position: relative;
        }

        .intro {
          display: block;
          margin: 0 auto;
          max-height: 0;
          max-width: rem(600);
          overflow: hidden;
          transition: all 0.8s ease-in-out;

          p {
            font-size: rem(16);
          }
        }

        .text {
          z-index: 1;
          padding: rem(60);
          color: $color-white;
        }

        &:after {
          content: '';
          display: block;
          position: relative;
          z-index: 0;
          width: 100%;
          @include grid-row(2, 2);
          background: transparent
            linear-gradient(180deg, #00000000 0%, #000000 100%) 0% 0% no-repeat
            padding-box;
        }

        @include hover(true) {
          .intro {
            max-height: rem(400);
          }
        }
      }

      @include page-break(2) {
        h2 {
          font-size: rem(50);
        }

        .intro {
          p {
            font-size: rem(20);
          }
        }

        .svg-service-icon {
          width: rem(24);
        }

        .services-block {
          padding-left: rem(60);
          padding-right: rem(60);

          .item {
            .title {
              font-size: rem(16);
            }
          }
        }
      }

      @include page-break(5) {
        .intro {
          p {
            font-size: rem(16);
          }
        }
      }
    }

    .highlighted {
      width: 100%;

      h2 {
        margin-bottom: math.div(10em, 30);
      }

      .image {
        @include grid-row(1);
        margin-bottom: rem(20);

        & > div {
          padding-top: math.div(262%, 3.28);

          @include page-break(1) {
            padding-top: 65%;
          }

          @include page-break(2) {
            padding-top: math.div(660%, 16.4);
          }
        }
      }

      .intro {
        display: block;
        max-width: rem(800);
      }

      .text {
        @include grid-row(2);
        margin-bottom: rem(30);
      }

      .services-block {
        @include grid-row(3);
      }

      @include page-break(1) {
        h2 {
          font-size: rem(50);
        }
      }

      @include page-break(2) {
        h2 {
          font-size: rem(60);
        }

        .intro {
          p {
            font-size: rem(24);
          }
        }

        @include hover(true) {
          .intro {
            max-height: rem(400);
          }
        }

        .svg-service-icon {
          margin-right: rem(20);
          width: rem(28);
        }

        .services-block {
          padding-bottom: rem(55);

          .item {
            margin: 0 rem(30) rem(20);

            .title {
              font-size: rem(20);
            }
          }
        }
      }

      @include page-break(3) {
        .services-block {
          padding-bottom: rem(97);
        }
      }

      @include page-break(5) {
        .intro {
          p {
            font-size: rem(20);
          }
        }
      }
    }

    .entry:not(.highlighted) {
      @include grid;
      @include grid-template-columns(repeat(11, 1fr 0.5rem) 1fr);
      @include grid-template-rows(auto #{rem(20)} auto);

      h2 {
        color: $color-white;
      }

      .image,
      .text {
        @include grid-row(1);
      }

      .text {
        @include grid-row-align(flex-end);
        position: relative;
        z-index: 1;
        padding: rem(37) rem(20) rem(30);
        background: transparent
          linear-gradient(180deg, #00000000 0%, #000000 100%) 0% 0% no-repeat
          padding-box;
      }

      .services-block {
        @include grid-row(3);
      }

      @include page-break(2) {
        width: calc(50% - #{rem(30)});

        .image {
          & > div {
            padding-top: 65%;
          }
        }
      }

      @include page-break(3) {
        width: calc(50% - #{rem(60)});

        .services-block {
          padding-bottom: rem(50);
          padding-left: rem(50);
          padding-right: rem(50);
        }

        .image {
          & > div {
            padding-top: math.div(438%, 7.6);
          }
        }
      }
    }

    .highlighted,
    .entry:not(.highlighted) {
      @include page-break(1) {
        @include grid-template-rows(auto auto auto);

        .image,
        .text,
        .services-block {
          margin-bottom: 0;
        }

        .image {
          @include grid-row(1, 3);
        }

        .text {
          @include grid-row(2);
          padding: rem(90) rem(60) rem(60);
          font-size: rem(14);
          background: none;
        }

        .services-block {
          @include grid-row(3);
          margin: 0 auto;
          max-width: rem(1200);
        }
      }
    }
  }
}

.project-entry {
  .section-header {
    .hint {
      .a {
        stroke: $color-cyan;
      }
    }
  }

  .project-header-image {
    margin-bottom: rem(30);
  }

  .widget-location {
    margin-bottom: rem(100);

    @include page-break(1) {
      margin-bottom: rem(130);
    }
  }

  @include page-break(2) {
    .project-header-image {
      margin-bottom: rem(-159);
    }

    .project-details {
      position: relative;
    }
  }
}
