@mixin service-icon($white: $color-base, $green: $color-cyan) {
  .white {
    * {
      &[stroke^='#'] {
        stroke: $white;
      }

      &[fill^='#'] {
        fill: $white;
      }
    }
  }

  .green {
    * {
      &[stroke^='#'] {
        stroke: $green;
      }

      &[fill^='#'] {
        fill: $green;
      }
    }
  }
}

@mixin hover($focus: false, $outline: false) {
  @if $focus {
    &:focus {
      @if $outline {
        outline: none;
      }

      @content;
    }
  }
  .no-touch & {
    &:hover {
      @content;
    }
  }
}

@mixin page-break($number) {
  @if $number == 0.25 {
    @media screen and (min-width: $break025) {
      @content;
    }
  } @else if $number == 0.5 {
    @media screen and (min-width: $break05) {
      @content;
    }
  } @else if $number == 0.75 {
    @media screen and (min-width: $break075) {
      @content;
    }
  } @else if $number == 1 {
    @media screen and (min-width: $break1) {
      @content;
    }
  // } @else if $number == 1.2 {
  //   @media screen and (min-width: $break12) {
  //     @content;
  //   }
  } @else if $number == 1.5 {
    @media screen and (min-width: $break15) {
      @content;
    }
  } @else if $number == 2 {
    @media screen and (min-width: $break2) {
      @content;
    }
  } @else if $number == 2.5 {
    @media screen and (min-width: $break25) {
      @content;
    }
  } @else if $number == 3 {
    @media screen and (min-width: $break3) {
      @content;
    }
  } @else if $number == 4 {
    @media screen and (min-width: $break4) {
      @content;
    }
  } @else if $number == 5 {
    @media screen and (min-width: $break5) {
      @content;
    }
  } @else if $number == 6 {
    @media screen and (min-width: $break6) {
      @content;
    }
  }
}

@mixin minHeight100vh($offset: 0) {
  @if $offset > 0 {
    min-height: calc(100vh - #{$offset}px);
    min-height: calc((var(--vh, 1vh) * 100) - #{$offset}px);
  } @else {
    min-height: 100vh;
    min-height: calc(var(--vh, 1vh) * 100);
  }

  @media print {
    min-height: 0 !important;
  }
}

@mixin height100vh($offset: 0) {
  @if $offset > 0 {
    height: calc(100vh - #{$offset});
    height: calc((var(--vh, 1vh) * 100) - #{$offset});
  } @else {
    height: 100vh;
    height: calc(var(--vh, 1vh) * 100);
  }

  @media print {
    height: auto !important;
  }
}

@mixin grid {
  display: -ms-grid;
  display: grid;
}

@mixin grid-template-rows($content) {
  -ms-grid-rows: $content;
  grid-template-rows: $content;
}

@mixin grid-template-columns($content) {
  -ms-grid-columns: $content;
  grid-template-columns: $content;
}

@mixin grid-row($row, $span: 0) {
  -ms-grid-row: $row !important;
  @if $span > 0 {
    -ms-grid-row-span: $span;
    grid-row: $row / span $span;
  } @else {
    -ms-grid-row-span: 1;
    grid-row: $row;
  }
}

@mixin grid-column($column, $span: 0) {
  -ms-grid-column: $column;
  @if $span > 0 {
    -ms-grid-column-span: $span;
    grid-column: $column / span $span;
  } @else {
    -ms-grid-column-span: 1;
    grid-column: $column;
  }
}

@mixin grid-row-align($align) {
  -ms-grid-row-align: $align;
  align-self: $align;
}

@mixin grid-column-align($align) {
  -ms-grid-column-align: $align;
  justify-self: $align;
}

@mixin full-grid-columns($start, $span: 1) {
  @if $start == 0 {
    @include grid-column(1, ($span * 2) - 3);
  } @else {
    @include grid-column(($start * 2), ($span * 2) - 1);
  }
}

@mixin full-grid-rows($start, $span: 1) {
  @include grid-row($start, $span);
}

@mixin content-grid-columns($start, $span: 1) {
  @if $start == 0 {
    @include grid-column(1, ($span * 2) - 3);
  } @else {
    @include grid-column(($start * 2), ($span * 2) - 1);
  }
}
