@use "sass:math";

@mixin font($type: 'default') {
  $heading: 'h1', 'h2', 'h3', 'h4', 'h5', 'h6';

  @if index($heading, $type) {
    line-height: 1.1;
    font-weight: 600;
  }

  @if $type == 'h1' {
    font-size: rem(50);

    // @include page-break(2) {
    //   font-size: rem(70);
    // }

    @include page-break(2) {
      font-size: rem(100);
    }
  } @else if $type == 'h2' {
    font-size: rem(30);

    // @include page-break(2) {
    //   font-size: rem(40);
    // }

    @include page-break(2) {
      font-size: rem(60);
    }
  } @else if $type == 'h3' {
    font-size: rem(20);

    @include page-break(2) {
      font-size: rem(36);
    }

    @include page-break(5) {
      font-size: rem(30);
    }
  } @else if $type == 'h4' {
    font-size: rem(20);
    font-weight: 400;

    // @include page-break(2) {
    //   font-size: rem(22);
    // }

    @include page-break(2) {
      font-size: rem(30);
    }

    @include page-break(5) {
      font-size: rem(25);
    }
  } @else if $type == 'h5' {
    font-size: rem(16);

    // @include page-break(2) {
    //   font-size: rem(18);
    // }

    @include page-break(2) {
      font-size: rem(24);
    }

    @include page-break(5) {
      font-size: rem(20);
    }
  } @else if $type == 'h6' {
    font-size: rem(12);
    font-weight: normal;
    line-height: math.div(18, 12);

    @include page-break(2) {
      font-size: rem(18);
    }

    @include page-break(5) {
      font-size: rem(14);
    }
  } @else if $type == 'intro' {
    font-size: rem(18);
    line-height: math.div(30, 20);

    // @include page-break(0.5) {
    //   font-size: rem(20);
    // }

    // @include page-break(2) {
    //   font-size: rem(22);
    // }

    @include page-break(2) {
      font-size: rem(30);
    }

    @include page-break(5) {
      font-size: rem(25);
    }
  } @else if $type == 'body' {
    font-size: rem(16);
    line-height: math.div(20, 16);

    // @include page-break(2) {
    //   font-size: rem(18);
    // }

    @include page-break(2) {
      font-size: rem(27);
      line-height: math.div(30, 20);
    }

    @include page-break(5) {
      font-size: rem(20);
      line-height: math.div(30, 20);
    }
  } @else if $type == 'blockquote' {
    font-size: rem(20);
    font-weight: 400;
    line-height: 2.1;

    // @include page-break(2) {
    //   font-size: rem(25);
    // }

    @include page-break(2) {
      font-size: rem(34);
    }

    @include page-break(5) {
      font-size: rem(30);
    }
  } @else if $type == 'li' {
    font-size: rem(16);
    line-height: math.div(20, 16);

    @include page-break(2) {
      font-size: rem(27);
      line-height: math.div(30, 20);
    }

    @include page-break(5) {
      font-size: rem(20);
      line-height: math.div(30, 20);
    }
  } @else {
    font-size: rem(16);
    font-weight: normal;
    line-height: math.div(20, 16);

    @include page-break(2) {
      font-size: rem(20);
    }

    @include page-break(5) {
      font-size: rem(16);
    }
  }
}

@mixin link {
  display: inline-block;
  position: relative;
  padding-bottom: 0.1em;
  color: $color-base;

  &::after {
    content: '';
    display: block;
    position: absolute;
    bottom: 0;
    left: 50%;
    width: 100%;
    height: 1px;
    background: $color-cyan;
    transform: translateX(-50%);
  }

  .no-touch & {
    &::after {
      width: 0%;
      transition: 0.2s ease-in-out;
    }

    &:hover {
      opacity: 1;

      &::after {
        width: 100%;
      }
    }
  }
}
