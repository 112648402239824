@use "sass:math";

.widget-gallery {
  padding: rem(60) 0;
  background: $gradient-gallery;

  h2 {
    margin-bottom: math.div(20em, 30);
  }

  header {
    margin-bottom: rem(43);
    text-align: center;
    color: $color-white;
  }

  .intro {
    opacity: 0.8;
  }

  .gallery {
    .navigation {
      button {
        opacity: 0.5;
        transition: 0.2s ease-in-out;

        @include hover() {
          opacity: 1;
        }
      }

      .svg-arrow {
        path {
          stroke: $color-white;
        }
      }
    }

    .slide {
      & > div {
        &::before {
          content: '';
          display: block;
          position: absolute;
          top: rem(60);
          bottom: 0;
          left: rem(40);
          right: rem(40);
          box-shadow: 0 0 rem(60) 0;
        }
      }
    }
  }

  @include page-break(2) {
    padding: rem(122) 0;
  }
}
