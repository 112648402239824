.project-details {
  margin-bottom: rem(60);
  color: $color-white;

  & > div {
    background: $color-blue-dark;
  }

  .grid-content {
    @include grid-template-rows(auto auto auto auto auto auto auto);
  }

  dt {
    margin-bottom: rem(5);
    font-size: rem(12);
    opacity: 0.6;
  }

  dd {
    font-weight: 500;
  }

  hr {
    @include grid-column(1, 23);
    border: none;
    height: 1px;
    background: $color-white;
    opacity: 0.2;
  }

  .services {
    @include grid-row(1);
    @include grid-column(3, 19);
    padding-top: rem(30);
    padding-bottom: rem(40);

    .svg {
      margin-right: rem(10);
      width: rem(23);
    }

    dt {
      margin-bottom: rem(20);
    }

    a {
      display: flex;
      margin-bottom: rem(10);
      color: $color-white;

      &:last-child {
        margin-bottom: 0;
      }
    }

    & + hr {
      @include grid-row(3);
    }
  }

  .type,
  .location,
  .size,
  .client {
    padding: rem(30) 0;
  }

  .type,
  .location {
    @include grid-row(3);
  }

  .type {
    @include grid-column(3, 8);

    & + hr {
      @include grid-row(3);
      @include grid-column(12);
      @include grid-column-align(center);
      height: auto;
      width: 1px;
    }
  }

  .location {
    @include grid-column(14, 8);

    & + hr {
      @include grid-row(4);
    }
  }

  .size {
    @include grid-row(5);
    @include grid-column(3, 19);

    & + hr {
      @include grid-row(6);
    }
  }

  .client {
    @include grid-row(7);
    @include grid-column(3, 19);

    & + hr {
      @include grid-row(6);
    }
  }

  @include page-break(1) {
    .grid-content {
      @include grid-template-rows(auto 1px auto 1px auto);
    }

    .services {
      @include grid-column(3, 15);

      & + hr {
        @include grid-row(2);
      }
    }

    .type,
    .location {
      @include grid-row(3);
    }

    .size,
    .client {
      @include grid-row(5);
    }

    .type,
    .size {
      @include grid-column(3, 5);

      & + hr {
        @include grid-column(10);
        @include grid-column-align(center);
        height: auto;
        width: 1px;
      }
    }

    .location,
    .client {
      @include grid-column(13, 5);
    }

    .type {
      & + hr {
        @include grid-row(3);
      }
    }

    .size {
      & + hr {
        @include grid-row(5);
      }
    }
  }

  @include page-break(2) {
    .grid-content {
      @include grid-template-rows(#{rem(158)} 1px auto);
    }

    dt {
      margin-bottom: rem(10);
      font-size: rem(24);
    }

    dd {
      font-size: rem(28);
    }

    .services,
    .type,
    .size,
    .location,
    .client {
      padding-top: rem(50);
      padding-bottom: rem(40);
    }

    .type,
    .size,
    .location,
    .client {
      padding-left: rem(40);
      padding-right: rem(40);
    }

    .services,
    .type {
      & + hr {
        @include grid-row(1, 3);
        height: auto;
        width: 1px;
      }
    }

    .services {
      @include grid-row(1, 3);
      @include grid-column(1, 9);

      padding-left: rem(60);
      padding-right: rem(60);

      & + hr {
        @include grid-column(10);
        @include grid-column-align(end);
      }
    }

    .type,
    .location {
      @include grid-row(1);
    }

    .size,
    .client {
      @include grid-row(3);
    }

    .type,
    .size {
      @include grid-column(11, 3);
    }

    .location,
    .client {
      @include grid-column(15, 5);
    }

    .type {
      & + hr {
        @include grid-column(14);
      }
    }

    .location {
      & + hr {
        @include grid-row(2);
        @include grid-column(11, 9);
      }
    }

    .size {
      & + hr {
        display: none;
      }
    }
  }

  @include page-break(5) {
    dt {
      font-size: rem(16);
    }

    dd {
      font-size: rem(20);
    }
  }
}
