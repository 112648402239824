@use "sass:math";

.person {
  .image {
    & > div {
      padding-top: math.div(280%, 2.6);
    }
  }
}

.overlay-top {
  @include grid-row(1);
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: rem(37) rem(40);
  width: 100%;

  .svg {
    width: 100%;
  }

  a {
    color: $color-base;
  }

  .svg-user,
  .svg-exit,
  .svg-hamburger {
    path {
      stroke: $color-cyan;
    }
  }

  .user {
    margin-top: rem(-2);
    width: rem(21);
  }

  .home {
    width: rem(150);
  }

  .close {
    width: rem(18);
  }

  .menu-toggle {
    width: rem(23);
  }

  @include page-break(2) {
    // $scale: 1 / 0.58;
    $scale: 1.4;

    padding: rem(37 * $scale) rem(40);

    .close {
      width: rem(18 * $scale);
    }
  }

  @include page-break(5) {
    padding: rem(37) rem(40);

    .close {
      width: rem(18);
    }
  }
}

.submenu,
.employee-overlay {
  position: fixed;
  top: 0;
  z-index: 10;
  width: 100%;

  height: 100%;
  @include minHeight100vh;
  background: transparent linear-gradient(25deg, #114c64 0%, #002a3b 100%) 0% 0%
    no-repeat padding-box;
  overflow: auto;
  scroll-behavior: smooth;
}

.submenu {
  right: 0;
  max-width: rem(675);
  transform: translateX(100%);

  & > div {
    @include grid;
    @include grid-template-columns(rem(23) repeat(11, 1fr 0.5rem) 1fr rem(23));
    @include grid-template-rows(auto auto auto auto);
    height: 100%;
  }

  .main,
  .sub {
    a {
      color: $color-base;
    }
  }

  .top {
    @include grid-row(1);
    @include full-grid-columns(0, 14);
    margin-bottom: rem(20);
  }

  .main {
    @include grid-row(2);
    @include full-grid-columns(2, 10);
    margin-bottom: rem(40);

    div {
      margin-bottom: calc(1em - 0.1em);
    }

    a {
      font-size: rem(25);
      font-weight: 600;

      // @include page-break(2) {
      //   font-size: rem(40);
      // }

      .touch & {
        &::after {
          width: 0%;
        }
      }
    }
  }

  .sub {
    @include grid-row(3);
    @include full-grid-columns(2, 10);
    margin-bottom: rem(40);

    div {
      margin-bottom: math.div(20em, 25);
    }

    a {
      font-size: rem(18);
      font-weight: 500;

      @include page-break(2) {
        font-size: rem(25);
      }

      .no-touch & {
        &::after {
          width: 100%;
        }
      }
    }
  }

  .search {
    @include grid-row(4);
    @include full-grid-columns(0, 14);
    @include grid-row-align(flex-end);
    @include grid;
    @include grid-template-columns(rem(23) repeat(11, 1fr 0.5rem) 1fr rem(23));
    padding: rem(40) 0 rem(40);
    width: 100%;
    background: rgba($color-base, 0.05);

    input {
      border: none;
      padding: rem(21) rem(35);
      width: 100%;
      font-size: rem(20);
      font-weight: 500;
      color: $color-base;
      background: rgba($color-base, 0.1);
    }

    button {
      position: absolute;
      top: 50%;
      right: rem(35);
      width: rem(18);
      transform: translateY(-50%);
      opacity: 0.5;

      .svg {
        width: 100%;

        path {
          stroke: $color-base;
        }
      }
    }

    .field {
      @include full-grid-columns(2, 10);
      position: relative;
      overflow: hidden;
      width: 100%;

      label {
        position: absolute;
        top: 100%;
        right: 100%;
      }
    }
  }

  @media (min-height: 43.75rem) {
    .main {
      a {
        font-size: rem(30);
      }
    }
  }

  @media (min-height: 50rem) {
    .top {
      margin-bottom: rem(80);
    }

    .sub {
      margin-bottom: rem(83);
    }

    .search {
      padding: rem(60) 0 rem(105);
    }
  }

  @include page-break(1) {
    & > div {
      @include grid-template-columns(repeat(5, 1fr 0.5rem) 1fr rem(23));
    }

    .top {
      @include grid-column(11);
      padding-left: 0;
      padding-right: rem(40 - 23);
      flex-direction: row-reverse;

      .user,
      .home {
        display: none;
      }
    }

    .main,
    .sub {
      @include grid-column(3, 7);
    }

    .search {
      @include grid-column(1, 12);
      @include grid-template-columns(repeat(5, 1fr 0.5rem) 1fr rem(23));

      .field {
        @include grid-column(3, 7);
      }
    }
  }

  @include page-break(2) {
    & > div,
    .search {
      @include grid-template-columns(repeat(3, 1fr 1rem) 1fr rem(70));
    }

    .top {
      @include grid-column(7);
      margin-bottom: rem(80);
      padding-right: 0;
    }

    .main,
    .sub {
      @include grid-column(3, 5);
    }

    .main {
      a {
        font-size: rem(40);
      }
    }

    .sub {
      margin-bottom: rem(83);
    }

    .search {
      @include grid-column(1, 8);
      padding: rem(60) 0 rem(105);

      .field {
        @include grid-column(3, 5);
      }
    }
  }

  @include page-break(3) {
    & > div,
    .search {
      @include grid-template-columns(repeat(3, 1fr 1rem) 1fr rem(100));
    }
  }

  @include page-break(2) {
    & > div,
    .search {
      @include grid-template-columns(repeat(3, 1fr 1rem) 1fr rem(140));
    }
  }
}

.employee-overlay {
  left: 0;
  max-width: rem(1400);
  transform: translateX(-100%);

  @include page-break(5) {
    max-width: rem(1090);
  }
}
