.grid {
  @include grid;
  @include grid-template-columns(rem(23) repeat(11, 1fr 0.5rem) 1fr rem(23));

  // @include page-break(2) {
  //   @include grid-template-columns(rem(70) repeat(11, 1fr 1rem) 1fr rem(70));
  // }

  // @include page-break(2.5) {
  //   @include grid-template-columns(rem(100) repeat(11, 1fr 1rem) 1fr rem(100));
  // }

  // @include page-break(3) {
  //   @include grid-template-columns(rem(120) repeat(11, 1fr 1rem) 1fr rem(120));
  // }

  @include page-break(2) {
    @include grid-template-columns(rem(140) repeat(11, 1fr 1rem) 1fr rem(140));
  }
}

.grid-span-6 {
  @include full-grid-columns(1, 12);

  @include page-break(0.5) {
    @include full-grid-columns(2, 10);
  }

  @include page-break(1) {
    @include full-grid-columns(3, 8);
  }

  @include page-break(2) {
    @include full-grid-columns(4, 6);
  }
}

.grid-span-8 {
  @include full-grid-columns(1, 12);

  @include page-break(1) {
    @include full-grid-columns(2, 10);
  }

  @include page-break(2) {
    @include full-grid-columns(3, 8);
  }
}

.grid-span-10 {
  @include full-grid-columns(1, 12);

  @include page-break(1) {
    @include full-grid-columns(2, 10);
  }
}

.grid-span-12 {
  @include full-grid-columns(1, 12);
}

.grid-span-full,
.grid-span-all {
  @include full-grid-columns(0, 14);
}

.grid-content {
  @include grid;

  .grid-span-12 & {
    @include grid-template-columns(repeat(11, 1fr 0.5rem) 1fr);

    @include page-break(2) {
      @include grid-template-columns(repeat(11, 1fr 1rem) 1fr);
    }
  }

  .grid-span-10 & {
    @include grid-template-columns(repeat(11, 1fr 0.5rem) 1fr);

    @include page-break(1) {
      @include grid-template-columns(repeat(9, 1fr 0.5rem) 1fr);
    }

    @include page-break(2) {
      @include grid-template-columns(repeat(9, 1fr 1rem) 1fr);
    }
  }

  .grid-span-8 & {
    @include grid-template-columns(repeat(11, 1fr 0.5rem) 1fr);

    @include page-break(1) {
      @include grid-template-columns(repeat(9, 1fr 0.5rem) 1fr);
    }

    // @include page-break(2) {
    //   @include grid-template-columns(repeat(9, 1fr 1rem) 1fr);
    // }

    @include page-break(2) {
      @include grid-template-columns(repeat(7, 1fr 1rem) 1fr);
    }
  }

  .grid-span-6 & {
    @include grid-template-columns(repeat(11, 1fr 0.5rem) 1fr);

    @include page-break(0.5) {
      @include grid-template-columns(repeat(9, 1fr 0.5rem) 1fr);
    }

    @include page-break(1) {
      @include grid-template-columns(repeat(7, 1fr 0.5rem) 1fr);
    }

    // @include page-break(2) {
    //   @include grid-template-columns(repeat(7, 1fr 1rem) 1fr);
    // }

    @include page-break(2) {
      @include grid-template-columns(repeat(5, 1fr 1rem) 1fr);
    }
  }
}

.grid-content-12 {
  @include grid;
  @include grid-template-columns(repeat(11, 1fr 0.5rem) 1fr);

  @include page-break(2) {
    @include grid-template-columns(repeat(11, 1fr 1rem) 1fr);
  }
}

.grid-content-10 {
  @include grid;
  @include grid-template-columns(repeat(9, 1fr 0.5rem) 1fr);

  @include page-break(2) {
    @include grid-template-columns(repeat(9, 1fr 1rem) 1fr);
  }
}
