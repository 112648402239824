@use "sass:math";

.map-container {
  position: relative;
  background: #071c24;

  .map {
    height: 100%;
  }
}

.contact-map,
.service-contact {
  h2 {
    margin-bottom: rem(40);
    font-size: rem(30);
  }

  .field-row {
    width: 100%;
    z-index: 3;

    label {
      position: absolute;
      top: -100%;
      left: -100%;
    }

    select {
      border: none;
      width: 100%;
      padding: rem(20) rem(20);
      padding-right: rem(44);
      appearance: none;
      font-size: rem(14);
      color: $color-white;
      background-color: transparent;

      background-repeat: no-repeat;
      background-position: right rem(20) center;

      &::-ms-expand {
        display: none;
      }

      &:focus {
        opacity: 1;

        option {
          color: $color-black;
        }
      }

      @include page-break(2) {
        font-size: rem(26);
      }

      @include page-break(5) {
        font-size: rem(20);
      }
    }
  }

  .select__menu-list {
    color: $color-white;
    background-color: $color-blue-medium;

    &::-webkit-scrollbar {
      width: rem(10);
    }

    &::-webkit-scrollbar-track {
      box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    }

    &::-webkit-scrollbar-thumb {
      background-color: $color-blue-light;
      outline: 1px solid slategrey;
    }
  }

  .select__indicator-separator {
    display: none;
  }

  .select__control {
    border: none;
    border-radius: 0;
    min-height: rem(55.6);
    font-size: rem(14);
    background: none;
  }

  .select__value-container {
    padding: 0 rem(20);
  }

  .select__indicator {
    padding-right: rem(30);

    .chevron {
      stroke: $color-white;
      opacity: 0.6;
    }
  }

  .select__placeholder,
  .select__single-value {
    color: $color-white;
    opacity: 0.6;
  }

  .select__control--is-focused {
    box-shadow: inset 0 0 0 rem(2) $color-blue-light;

    .select__placeholder,
    .select__single-value {
      opacity: 1;
    }

    .select__indicator {
      .chevron {
        opacity: 1;
        stroke: $color-cyan;
      }
    }
  }

  .select__option {
    background-color: $color-blue-medium;
    transition: all 0.2s ease-in-out;
  }

  .select__option--is-focused {
    background-color: lighten($color-blue-light, 10);
    color: $color-black;
  }

  .select__option--is-selected {
    color: $color-white;
    background-color: $color-blue-dark;
  }

  @include page-break(2) {
    .select__control {
      min-height: rem(61.5);
      font-size: rem(22);
    }

    .select__option {
      font-size: rem(22);
    }
  }

  @include page-break(5) {
    .select__control {
      min-height: rem(60);
      font-size: rem(18);
    }

    .select__option {
      font-size: rem(18);
    }
  }
}

.contact-map {
  @include grid-template-rows(#{rem(60)} auto auto auto #{rem(120)});
  color: $color-white;
  overflow: hidden;

  &::before {
    content: '';
    @include grid-row(1, 5);
    @include full-grid-columns(0, 14);
    display: block;
    position: relative;
    z-index: 1;
    background: transparent linear-gradient(343deg, #114c64 0%, #002a3b 100%) 0%
      0% no-repeat padding-box;
  }

  h4 {
    font-size: rem(12);
    font-weight: 500;
    opacity: 0.5;

    @include page-break(1) {
      font-size: rem(16);
    }

    @include page-break(2) {
      font-size: rem(22);
    }

    @include page-break(5) {
      font-size: rem(16);
    }
  }

  h3 {
    margin-bottom: rem(25);
  }

  h4 {
    margin-bottom: rem(10);
  }

  a {
    color: $color-white;
  }

  .field-row {
    background: $color-blue-medium;

    select {
      background-image: url(~gfx/chevron-down-cyan.svg);
    }
  }

  header,
  .map-container,
  .address-container {
    @include full-grid-columns(1, 12);
  }

  header {
    @include grid-row(2);
    margin-bottom: rem(30);
    z-index: 2;
  }

  .map-container {
    @include grid-row(3);
    margin-bottom: rem(20);
    height: rem(327);
    z-index: 2;
  }

  .companies,
  .company {
    @include grid-row(4);
    @include full-grid-columns(0, 14);
    padding: rem(20) rem(23) 0;
    z-index: 2;

    &.enter {
      z-index: 0;
      opacity: 0;
    }

    &.enter-active {
      opacity: 1;
      z-index: 2;
      transition: all 0.4s ease-in-out;
    }

    &.exit {
      z-index: 0;
    }
  }

  .companies {
    position: relative;

    button {
      margin-bottom: 1em;
      padding-bottom: 0.3em;
      font-size: rem(16);

      @include page-break(2) {
        font-size: rem(26);
      }

      @include page-break(5) {
        font-size: rem(20);
      }
    }
  }

  .show-company {
    .companies {
      margin-left: -50%;
    }
  }

  .company {
    position: relative;

    .back {
      margin-bottom: rem(40);
      font-size: rem(12);
      font-weight: 500;
      transition: 0.2s ease-in-out;

      & > span {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        color: $color-white;
      }

      .text {
        opacity: 0.8;
      }

      .svg-arrow-left {
        margin-right: rem(19);
        width: rem(14);

        .b {
          stroke: $color-cyan;
        }
      }

      @include hover {
        .text {
          opacity: 1;
        }
      }
    }

    h3 {
      margin-bottom: rem(40);
    }

    strong {
      display: block;
      margin-bottom: 0.5em;
      font-weight: normal;

      &:last-child {
        margin-bottom: 0;
      }

      & + br {
        display: none;
      }
    }

    .contact-methods {
      & > div {
        &:first-child {
          padding-bottom: rem(60);
          border-bottom: 1px solid rgba($color-white, 0.1);
          margin-bottom: rem(60);
        }
      }

      .visit,
      .invoice,
      .post,
      .phone {
        margin-bottom: rem(30);
      }

      .invoice {
        a {
          @include link;
          white-space: nowrap;
        }
      }

      p {
        @include page-break(2) {
          font-size: rem(26);
        }

        @include page-break(5) {
          font-size: rem(18);
        }
      }

      .link {
        white-space: nowrap;

        @include page-break(2) {
          font-size: rem(26);
        }

        @include page-break(5) {
          font-size: rem(18);
        }
      }

      @include page-break(0.5) {
        & > div {
          @include grid;
          @include grid-template-columns(1fr 0.5rem 1fr);
          @include grid-template-rows(auto #{rem(30)} auto);

          @include page-break(2) {
            @include grid-template-columns(1fr 1rem 1fr);
          }
        }

        .visit,
        .invoice,
        .post,
        .phone {
          margin-bottom: 0;
        }

        .visit,
        .phone {
          @include grid-column(1);
          @include grid-row(1);
        }

        .visit {
          & ~ .phone {
            @include grid-row(3);
          }
        }

        .post,
        .email {
          @include grid-column(3);
          @include grid-row(1);
        }

        .post {
          & ~ .email {
            @include grid-row(3);
          }
        }

        .invoice,
        .electronic {
          @include grid-row(1);
        }

        .invoice {
          @include grid-column(1, 3);

          & ~ .electronic {
            @include grid-row(3);
          }

          p {
            @include grid;
            @include grid-template-columns(1fr 0.5rem 1fr);

            @include page-break(2) {
              @include grid-template-columns(1fr 1rem 1fr);
            }

            strong,
            span {
              display: block;
              @include grid-column(1);
            }

            strong {
              & + span {
                @include grid-column(3);
              }
            }
          }
        }

        .electronic {
          @include grid-column(1, 3);
        }
      }
    }
  }

  &.with-companies {
    .companies {
      z-index: 3 !important;
      transition: 0.4s ease-in-out;
    }

    .company {
      transform: translateX(100%);
      z-index: 2;

      &.enter {
        z-index: 2;
        opacity: 1;
        transform: translateX(100%);
      }

      &.enter-active {
        transform: translateX(0%);
        transition: 0.4s ease-in-out;
      }

      &.enter-done {
        transform: translateX(0%);
      }

      &.exit {
        transform: translateX(0%);
      }

      &.exit-active {
        transform: translateX(100%);
        transition: 0.4s ease-in-out;
      }
    }

    &.show-company {
      .companies {
        transform: translateX(-100%);
      }
    }
  }

  @include page-break(1.5) {
    @include grid-template-rows(auto auto);

    &::before {
      content: '';
      @include grid-row(1, 2);
      @include grid-column(2, 12);
    }

    header {
      @include grid-row(1);
      @include grid-column(2, 12);
      padding: rem(50) rem(42) rem(50);
      z-index: 4;
    }

    .companies,
    .company {
      @include grid-row(1, 2);
      @include grid-column(2, 12);
      padding: rem(232) rem(42) rem(50);
      z-index: 2;
    }

    .company {
      .back {
        position: absolute;
        top: rem(142);
        left: rem(42);
        width: rem(22);

        .text {
          display: none;
        }

        .svg {
          width: 100%;

          path {
            stroke: $color-cyan;
          }
        }
      }
    }

    .companies {
      min-height: rem(730);
    }

    .map-container {
      @include grid-row(1, 2);
      @include full-grid-columns(7, 6);
      margin-bottom: 0;
      height: auto;
      z-index: 0;
    }

    &.with-companies {
      .companies {
        z-index: 3 !important;
        transition: 0.4s ease-in-out;
      }

      .company {
        z-index: 0 !important;
        background: transparent
          linear-gradient(325deg, #114c64 0%, #002a3b 100%) 0% 0% no-repeat
          padding-box;

        &.enter {
          transform: translateX(0%);
        }

        &.enter-active {
          transform: translateX(100%);
          transition: 0.4s ease-in-out;
        }

        &.enter-done {
          transform: translateX(100%);
        }

        &.exit {
          transform: translateX(100%);
        }

        &.exit-active {
          transform: translateX(0%);
          transition: 0.4s ease-in-out;
        }
      }

      &.show-company {
        .companies {
          transform: none;
        }
      }
    }
  }

  @include page-break(3) {
    &::before {
      @include grid-column(2, 10);
    }

    header {
      @include grid-column(2, 10);
    }

    .companies,
    .company {
      @include grid-column(2, 10);
    }

    .map-container {
      @include full-grid-columns(6, 7);
    }
  }

  @include page-break(4) {
    header {
      padding: rem(100) rem(50) rem(50);
    }

    .companies,
    .company {
      padding: rem(280) rem(50) rem(100);
    }

    .company {
      .back {
        top: rem(190);
      }
    }
  }

  @include page-break(2) {
    header {
      padding: rem(100) rem(84) rem(50);
    }

    .companies,
    .company {
      padding: rem(280) rem(84) rem(100);
    }
  }
}

service-contact-component {
  padding: rem(60) 0 rem(95);
  background: transparent linear-gradient(160deg, #1d6e8e 0%, #002a3b 100%) 0%
    0% no-repeat padding-box;

  @include page-break(1) {
    padding: 0;
    background: none;
  }
}

.service-contact {
  header {
    margin-bottom: rem(20);
    color: $color-base;

    .body {
      a {
        color: $color-white;
      }
    }
  }

  .field-row {
    margin-bottom: rem(30);
    background: $color-green-dark;

    select {
      background-image: url(~gfx/chevron-down.svg);
    }
  }

  .map-container {
    margin-bottom: rem(20);
    height: rem(327);
  }

  & > .person {
    &.enter {
      & > div {
        opacity: 0;
      }
    }

    &.enter-active {
      & > div {
        opacity: 1;
        transition: opacity 0.4s ease-in;
      }
    }

    &.exit {
      opacity: 0;
    }
  }

  .person {
    width: 50%;
  }

  .employees {
    color: $color-base;

    &.enter {
      opacity: 0;
    }

    &.enter-active {
      opacity: 1;
      z-index: 2;
      transition: opacity 0.4s ease-in;
    }

    &.exit {
      opacity: 0;
    }
  }

  @include page-break(0.25) {
    .employees {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      align-items: flex-start;
    }
  }

  @include page-break(1) {
    @include grid;
    @include grid-template-columns(repeat(11, 1fr 0.5rem) 1fr);
    @include grid-template-rows(#{rem(80)} min-content #{rem(80)} auto #{rem(60)});

    &:before {
      content: '';
      display: block;
      @include grid-row(1, 5);
      @include grid-column(1, 11);
      background: transparent linear-gradient(139deg, #1d6e8e 0%, #002a3b 100%)
        0% 0% no-repeat padding-box;
    }

    header {
      @include grid-column(3, 7);
      @include grid-row(2);
      margin-bottom: rem(40);
    }

    .field-row,
    .person {
      @include grid-row-align(flex-start);
    }

    .field-row {
      @include grid-column(3, 7);
      @include grid-row(3);
    }

    .map-container {
      @include grid-column(12, 12);
      @include grid-row(1, 5);
      margin-bottom: 0;
      height: auto;
    }

    .person {
      @include grid-column(3, 7);
      @include grid-row(4);
      width: auto;
    }

    .employees {
      @include grid-row(4);
      @include grid-column(3, 7);
      max-height: 40vh;
      overflow: auto;

      &::-webkit-scrollbar {
        width: rem(15);
      }

      &::-webkit-scrollbar-track {
        box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
        background: rgba($color-blue-light, 0.2);
      }

      &::-webkit-scrollbar-thumb {
        background-color: $color-blue-light;
        outline: 1px solid slategrey;
      }

      .person {
        width: calc(50% - 0.25rem);
        width: 100%;
      }
    }
  }

  @include page-break(2) {
    @include grid-template-columns(repeat(11, 1fr 1rem) 1fr);
    min-height: 90vh;

    .field-row,
    .person {
      @include grid-column(3, 3);
    }

    .employees {
      @include grid-row(4);
      @include grid-column(3, 7);
      max-height: 30vw;

      .person {
        margin-right: 0;
        width: calc(50% - 0.5rem);
      }
    }
  }

  @include page-break(3) {
    @include grid-template-rows(#{rem(100)} min-content #{rem(110)} auto #{rem(80)});
  }

  @include page-break(5) {
    .employees {
      max-height: 25vw;
    }
  }
}
