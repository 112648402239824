.widget-vimeo {
  .vimeo-container {
    position: relative;
    background: $color-green-medium;
  }

  .facade,
  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}
