@use "sass:math";

@keyframes hint {
  0% {
    transform: translateY(#{rem(-10)});
  }
  100% {
    transform: translateY(#{rem(10)});
  }
}

.section-header,
.service-header,
.page-header {
  box-sizing: border-box;
  position: relative;
  padding: 0;
  text-align: center;

  canvas {
    display: block;
    position: relative;
    z-index: 0;
    width: 100%;
    height: 100%;
  }
}

.search-header,
.service-header {
  &::after {
    content: '';
    display: block;
    position: absolute;
    bottom: -1px;
    left: 50%;
    z-index: 0;
    transform: translateX(-50%);
    width: 0;
    height: 0;
    border: rem(70) solid transparent;
    border-top-width: 0;
    border-bottom-width: rem(30);
    border-bottom-color: $color-white;
    @include grid-row(6);
    @include full-grid-columns(5, 4);
    @include grid-column-align(center);
    @include grid-row-align(flex-end);
  }
}

.service-header {
  height: auto;
  min-height: calc(100vh - #{rem(160)});

  @include grid-template-rows(
    #{rem(86)} 0.7fr min-content min-content 1fr #{rem(86)}
  );

  @include grid-template-rows(
    #{rem(86)} 0.7fr min-content min-content 1fr #{rem(60)}
  );

  color: $color-base;
  background: transparent linear-gradient(293deg, #114c64 0%, #002a3b 100%) 0%
    0% no-repeat padding-box;

  .tp-rotv {
    display: none;
    @include grid-row(6);
  }

  h1,
  .h1,
  .intro,
  service-menu-component {
    @include full-grid-columns(1, 12);
    z-index: 1;
  }

  h1,
  .h1 {
    @include grid-row-align(flex-end);
    @include grid-row(3);
    color: $color-blue-light;
  }

  .intro {
    @include grid-row(4);
    margin: 0 auto rem(30);
    max-width: rem(732);
    opacity: 0.8;
  }

  service-menu-component {
    @include grid-row(5);
    @include grid-row-align(flex-end);
  }

  canvas {
    @include grid-row(1, 6);
  }

  @include page-break(1) {
    .intro {
      margin-bottom: rem(120);
      width: 60%;
    }
  }

  @include page-break(5) {
    service-menu-component {
      @include full-grid-columns(1, 12);
    }
  }

  @media (min-height: 600px) {
    min-height: 0;
  }

  @media (min-height: 600px) and (min-width: $break2) {
    height: calc(100vh - #{rem(230)});
    max-height: rem(1000);
  }

  @media (min-height: 600px) and (min-width: $break5) {
    height: auto;
    max-height: none;
  }
}

.section-header,
.page-header {
  @include grid-template-rows(#{rem(120)} 1fr auto 1fr #{rem(86)});
  min-height: calc(100vh - #{rem(120)});
  text-align: center;

  canvas {
    @include grid-row(1, 5);
    max-height: calc(100vh - #{rem(120)});
  }

  .breadcrumbs {
    @include grid-row(2);
    @include grid-column-align(center);
    @include grid-row-align(start);
    position: relative;
    z-index: 2;
    padding-top: rem(25);
    display: flex;
    font-size: rem(12);

    a,
    span {
      color: $color-white;
      opacity: 0.5;
    }

    a {
      transition: opacity 0.2s ease-in-out;

      &:after {
        content: '/';
        display: inline-block;
        margin: 0 0.5em;
      }

      @include hover(true) {
        opacity: 1;
      }
    }

    @include page-break(2) {
      padding-top: rem(76);
      font-size: rem(20);
    }

    @include page-break(5) {
      font-size: rem(14);
    }
  }

  .header-text {
    @include grid-row(3);
    position: relative;
    z-index: 1;
  }

  .hint {
    .text {
      font-size: rem(16);
    }
  }

  hint-component {
    display: block;
    position: relative;
    z-index: 3;
    @include grid-row(5);
    @include grid-column-align(center);
    @include grid-row-align(center);

    button {
      .svg {
        animation-name: hint;
        animation-timing-function: ease-in-out;
        animation-duration: 1.2s;
        animation-iteration-count: infinite;
        animation-direction: alternate;
      }
    }

    .svg {
      width: rem(20);

      path {
        stroke: $color-green-medium;
      }
    }
  }

  @include page-break(2) {
    min-height: rem(994);

    canvas {
      max-height: rem(994);
    }
  }

  @include page-break(5) {
    min-height: 75vh;

    canvas {
      max-height: 75vh;
    }
  }
}

.homepage-header {
  @include minHeight100vh;
  color: $color-white;
  background: $gradient-home-top;

  h1 {
    margin-bottom: rem(20);
    font-size: rem(120);
  }

  .intro {
    margin: 0 auto;
    max-width: 600px;
  }

  hint-component {
    .svg {
      path {
        stroke: $color-cyan;
      }
    }
  }

  @include page-break(2) {
    h1,
    .h1 {
      font-size: rem(180);
    }

    .intro {
      width: 80%;

      p {
        font-size: rem(35);
      }
    }
  }

  @include page-break(3) {
    h1,
    .h1 {
      font-size: rem(260);
    }

    .intro {
      max-width: none;
    }
  }

  @media (min-height: 600px) {
    @include height100vh;
  }
}

.page-header {
  margin-bottom: rem(80);

  .breadcrumbs {
    a,
    span {
      color: $color-black;
    }
  }

  .hint {
    .text {
      font-size: rem(22);
      color: $color-black;
    }
  }
}

.section-header {
  color: $color-white;
  background: transparent linear-gradient(293deg, #114c64 0%, #002a3b 100%) 0%
    0% no-repeat padding-box;

  h1,
  .h1 {
    color: $color-blue-light;
  }

  .intro {
    opacity: 0.8;
  }
}
