@import '~utils/__utils';

.employees {
  .filter {
    .filter__menu-list {
      width: calc(200% + 0.5rem);
      color: $color-white;
      background-color: $color-blue-medium;

      &::-webkit-scrollbar {
        width: rem(10);
      }

      &::-webkit-scrollbar-track {
        box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
      }

      &::-webkit-scrollbar-thumb {
        background-color: $color-blue-light;
        outline: 1px solid slategrey;
      }
    }

    .filter__indicator-separator {
      display: none;
    }

    .filter__control {
      border: none;
      border-radius: 0;
      min-height: rem(55.6);
      font-size: rem(14);
      background: none;
    }

    .filter__value-container {
      padding: 0 rem(20);
    }

    .filter__indicator {
      padding-right: rem(30);

      .chevron {
        stroke: $color-white;
        opacity: 0.6;
      }
    }

    .filter__placeholder,
    .filter__single-value {
      color: $color-white;
      opacity: 0.6;
    }

    .filter__control--is-focused {
      box-shadow: inset 0 0 0 rem(2) $color-blue-light;

      .filter__placeholder,
      .filter__single-value {
        opacity: 1;
      }

      .filter__indicator {
        .chevron {
          opacity: 1;
          stroke: $color-cyan;
        }
      }
    }

    .filter__option {
      background-color: $color-blue-medium;
      transition: all 0.2s ease-in-out;
    }

    .filter__option--is-focused {
      // background-color: rgba($color-blue-medium, 0.2);
      background-color: lighten($color-blue-light, 10);
      // background-color: $color-blue-light;
      color: $color-black;
      // background-color: $color-white;
    }

    .filter__option--is-selected {
      color: $color-white;
      background-color: $color-blue-dark;

      // color: $color-black;
      // background-color: $color-cyan;
    }

    .field-company {
      .filter__menu {
        direction: rtl;
      }

      .filter__option {
        direction: ltr;
      }
    }

    .item {
      display: flex;
      justify-content: flex-start;
      align-items: center;
    }

    .svg-service-icon {
      margin-right: rem(12);
      width: rem(21);
      min-width: rem(21);

      @include service-icon($color-white, $color-white);
    }

    .filter__option--is-selected,
    .filter__option--is-focused {
      .svg-service-icon {
        @include service-icon($color-black, $color-black);
      }
    }

    .filter__option--is-selected {
      .svg-service-icon {
        @include service-icon($color-white, $color-white);
      }
    }

    @include page-break(2) {
      .filter__menu-list {
        width: calc(200% + 1rem);
      }

      .filter__control {
        min-height: rem(61.5);
        font-size: rem(22);
      }

      .filter__option {
        font-size: rem(22);
      }

      .svg-service-icon {
        margin-right: rem(12);
        width: rem(30);
        min-width: rem(30);
      }
    }

    @include page-break(5) {
      .filter__control {
        min-height: rem(60);
        font-size: rem(18);
      }

      .filter__option {
        font-size: rem(18);
      }

      .svg-service-icon {
        width: rem(21);
        min-width: rem(21);
      }
    }
  }
}
