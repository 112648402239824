.about-entry {
  expandable-component {
    display: block;
    margin-bottom: rem(10);

    @include page-break(1.5) {
      margin-bottom: rem(20);
    }

    contact-map-component {
      display: block;
      min-height: rem(600);
    }
  }

  .history {
    .expandable {
      & > .content {
        display: block;

        & > div {
          @include grid-column(1, 10);
        }
      }
    }

    .blocks {
      padding-bottom: 0;

      .grid {
        @include grid-template-columns(repeat(11, 1fr 0.5rem) 1fr);

        @include page-break(2) {
          @include grid-template-columns(repeat(11, 1fr 1rem) 1fr);
        }
      }

      .grid-span-6 {
        @include grid-column(3, 19);

        @include page-break(0.5) {
          @include grid-column(5, 15);
        }

        @include page-break(1) {
          @include grid-column(7, 11);
        }
      }

      .grid-span-8 {
        @include grid-column(1, 23);

        @include page-break(0.5) {
          @include grid-column(3, 19);
        }

        @include page-break(1) {
          @include grid-column(5, 15);
        }
      }

      .widget-blockquote {
        blockquote {
          @include grid-column(3, 19);

          @include page-break(0.5) {
            @include grid-column(3, 15);
          }

          @include page-break(1) {
            @include grid-column(5, 11);
          }
        }
      }

      .widget-image-group {
        .image {
          &:nth-child(2) {
            @include grid-column(3, 10);
          }

          &:nth-child(3) {
            @include grid-column(14, 8);
          }
        }

        .decor {
          @include grid-column(5, 14);
        }

        @include page-break(1) {
          .image {
            &:nth-child(2) {
              @include grid-column(5, 7);
            }

            &:nth-child(3) {
              @include grid-column(14, 6);
            }
          }

          .decor {
            @include grid-column(7, 12);
          }
        }
      }
    }
  }

  .contact-map {
    @include grid-template-rows(#{rem(0)} auto auto auto #{rem(120)});
    color: $color-black;

    &::before {
      background: $color-gray;

      @include page-break(1.5) {
        @include grid-column(1, 9);
      }
    }

    h2 {
      display: none;
    }

    a {
      color: $color-black;
    }

    .contact-methods .invoice a,
    .link {
      color: $color-black;

      &::after {
        background: $color-green-medium;
      }
    }



    .back {
      & > span {
        color: $color-black;
      }

      .svg-arrow-left .b {
        stroke: $color-green-medium;
      }
    }

    .svg-exit {
      path {
        stroke: $color-green-medium !important;
      }
    }

    .company {
      background: $color-gray;

      .contact-methods > div:first-child {
        border-color: $color-gray-medium;
      }
    }

    @include page-break(1.5) {
      @include grid-template-columns(repeat(11, 1fr 0.5rem) 1fr);
      @include grid-template-rows(#{rem(60)} auto);

      h2 {
        display: block;
      }

      header,
      .companies,
      .company {
        @include grid-column(1, 9);
      }

      .map-container {
        @include grid-column(10, 14);
      }
    }
  }

  .contact {
    margin-bottom: rem(100);

    @include page-break(1.5) {
      padding: 0 rem(23);
    }

    @include page-break(2) {
      margin-bottom: rem(160);
      padding: 0 rem(140);
    }

    .expandable {
      & > button {
        box-sizing: border-box;
        margin: 0 rem(23);
        width: calc(100% - #{rem(46)});

        @include page-break(1.5) {
          margin: 0;
          width: 100%;
        }
      }

      & > .content {
        display: block;

        & > div {
          @include grid-column(1, 10);

          @include page-break(1.5) {
            padding: 0;
          }
        }
      }
    }
  }
}
