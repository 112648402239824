@use "sass:math";

@media screen {
  .appear-enter {
    opacity: 0;
    transform: translateY(#{rem(60)});
  }

  .appear-enter-active {
    transform: translateY(0rem);
    opacity: 1;
    transition: all 0.9s $ease-out-cubic;
  }

  .widget-image-group {
    .appear-enter {
      opacity: 0;
      transform: translateY(#{rem(50)});
    }

    .appear-enter-active {
      transform: translateY(0rem);
      opacity: 1;
      transition: all 0.9s $ease-out-cubic;
    }
  }

  .employee-overlay {
    .appear-enter {
      transform: translateY(0rem);
      opacity: 1;
    }

    .appear-enter-active {
      transition: none;
    }
  }

  .vision-carousel {
    $offset: 0.4s;
    $stagger: 0.18s;

    &.stagger-enter {
      opacity: 0;
    }

    &.stagger-enter-active {
      opacity: 1;
      transition: all 1.25s;
    }
  }

  .homepage-header {
    opacity: 1;
    transform: none;

    &.stagger-enter {
      h1,
      .intro {
        opacity: 0;
        transform: translateY(#{rem(40)});
      }
    }

    &.stagger-enter-active {
      h1,
      .intro {
        transform: translateY(0rem);
        opacity: 1;
        transition: all 0.9s $ease-out-cubic 0.4s;
      }

      .intro {
        transition-delay: 0.5s;
      }
    }
  }

  .widget-services {
    &.stagger-enter {
      h2,
      .text,
      .list {
        opacity: 0;
        transform: translateY(#{rem(40)});
      }
    }

    &.stagger-enter-active {
      h2,
      .text,
      .list {
        transition: all 1s $ease-out-cubic;
        opacity: 1;
        transform: translateY(#{rem(0)});
      }

      $offset: 0.4s;
      $stagger: 0.18s;

      h2 {
        transition-delay: ($offset + ($stagger * 0));
      }

      .text {
        transition-delay: ($offset + ($stagger * 1));
      }

      .list {
        transition-delay: ($offset + ($stagger * 2));
      }
    }
  }

  .widget-entry {
    opacity: 1;
    transform: none;

    &.stagger-enter {
      &::before {
        transform: translate(0.5rem, 0.5rem);
        opacity: 0;
      }

      .image,
      h2,
      .body,
      .link {
        opacity: 0;
        transform: translateY(#{rem(40)});
      }
    }

    &.stagger-enter-active {
      &::before {
        transition: all 1.1s $ease-out-cubic 0.4s;
        transform: translate(1.5rem, 1.5rem);
        opacity: 1;

        @include page-break(2) {
          transform: translate(2rem, 2rem);
        }
      }

      .image {
        transition: all 1.4s $ease-out-cubic;
        opacity: 1;
        transform: translateY(#{rem(0)});
      }

      h2,
      .body,
      .link {
        transition: all 1s $ease-out-cubic;
        opacity: 1;
        transform: translateY(#{rem(0)});
      }

      $offset: 0.4s;
      $stagger: 0.18s;

      h2 {
        transition-delay: 0.4s;
      }

      .body {
        transition-delay: ($offset + ($stagger * 1));
      }

      .link {
        transition-delay: ($offset + ($stagger * 2));
      }
    }
  }

  .reference-carousel {
    header {
      &.stagger-enter {
        h2,
        p {
          opacity: 0;
          transform: translateY(#{rem(40)});
        }
      }

      &.stagger-enter-active {
        $offset: 0.4s;
        $stagger: 0.18s;

        h2,
        p {
          transition: all 1s $ease-out-cubic;
          opacity: 1;
          transform: translateY(#{rem(0)});
        }

        h2 {
          transition-delay: ($offset + ($stagger * 0));
        }

        p {
          transition-delay: ($offset + ($stagger * 1));
        }
      }
    }
  }

  .carousel {
    &.stagger-enter {
      .track,
      nav {
        opacity: 0;
        transform: translateY(#{rem(40)});
      }
    }

    &.stagger-enter-active {
      $offset: 0.4s;
      $stagger: 0.18s;

      .track,
      nav {
        transition: all 1s $ease-out-cubic;
        opacity: 1;
        transform: translateY(#{rem(0)});
      }

      .track {
        transition-delay: ($offset + ($stagger * 0));
      }

      nav {
        transition-delay: ($offset + ($stagger * 1));
      }
    }
  }

  .page-header,
  .section-header {
    $offset: 0.4s;
    $stagger: 0.18s;

    &.stagger-enter {
      h1,
      .h1,
      .intro,
      .hits,
      form {
        opacity: 0;
        transform: translateY(#{rem(40)});
      }

      .breadcrumbs,
      hint-component {
        opacity: 0;
      }
    }

    &.stagger-enter-active {
      h1,
      .h1,
      .intro,
      .hits,
      form {
        transition: all 1s $ease-out-cubic;
        opacity: 1;
        transform: translateY(#{rem(0)});
      }

      .breadcrumbs,
      hint-component {
        transition: opacity 1s $ease-out-cubic;
        opacity: 1;
      }

      h1,
      .h1 {
        transition-delay: ($offset + ($stagger * 0));
      }

      .intro,
      .hits {
        transition-delay: ($offset + ($stagger * 1));
      }

      form,
      .breadcrumbs,
      hint-component {
        transition-delay: ($offset + ($stagger * 2));
      }
    }
  }

  .service-header {
    $offset: 0.4s;
    $stagger: 0.18s;

    &.stagger-enter {
      h1,
      .h1,
      .intro,
      service-menu-component {
        opacity: 0;
        transform: translateY(#{rem(40)});
      }
    }

    &.stagger-enter-active {
      h1,
      .h1,
      .intro,
      service-menu-component {
        transition: all 1s $ease-out-cubic;
        opacity: 1;
        transform: translateY(#{rem(0)});
      }

      h1,
      .h1 {
        transition-delay: ($offset + ($stagger * 0));
      }

      .intro {
        transition-delay: ($offset + ($stagger * 1));
      }

      service-menu-component {
        transition-delay: ($offset + ($stagger * 2));
      }
    }
  }

  .list-stagger-enter {
    li {
      opacity: 0;
      transform: translateY(1em);
    }
  }

  .list-stagger-enter-active {
    $offset: 0s;
    $stagger: 0.18s;

    li {
      transition: all 1s $ease-out-cubic;
      opacity: 1;
      transform: translateY(0em);
    }

    @for $i from 1 through 30 {
      li:nth-child(#{$i}) {
        transition-delay: ($offset + ($stagger * ($i - 1)));
      }
    }
  }

  .widget-badges {
    $offset: 0s;
    $stagger: 0.18s;

    .list {
      &.stagger-enter {
        .badge {
          opacity: 0;
          transform: translateY(#{rem(40)});
        }
      }

      &.stagger-enter-active {
        .badge {
          transition: all 1s $ease-out-cubic;
          opacity: 1;
          transform: translateY(#{rem(0)});

          @for $i from 1 through 10 {
            &:nth-child(#{$i}) {
              transition-delay: ($offset + ($stagger * ($i - 1)));
            }
          }
        }
      }
    }
  }
}
