.image {
  & > div {
    position: relative;
    width: 100%;
    height: 0;
    background: lighten($color-blue-gray, 20%);

    img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      font-family: 'object-fit: cover;';
    }
  }
}

.image-full {
  margin-bottom: rem(60);

  @include page-break(1) {
    margin-bottom: rem(80);
  }

  @include page-break(2) {
    margin-bottom: rem(100);
  }

  & > div {
    box-sizing: border-box;

    @media (orientation: landscape) {
      padding-top: 0 !important;
      @include height100vh;
    }
  }
}
