@use "sass:math";

.search-index {
  .section-header {
    .hits {
      margin-bottom: rem(60);
      font-size: rem(20);

      em {
        font-style: normal;
        font-weight: 600;
      }

      @include page-break(2) {
        font-size: rem(30);
      }

      @include page-break(5) {
        font-size: rem(25);
      }
    }

    form {
      @include grid;
      @include grid-template-columns(auto #{rem(76)});
      @include grid-template-rows(auto);
      margin: rem(20) auto 0;
      max-width: rem(540);
      position: relative;
      background: #235365;
      overflow: hidden;

      label {
        position: absolute;
        top: 100%;
        left: 100%;
      }

      button {
        @include grid-row(1);
        @include grid-column(2);
        display: flex;
        justify-content: center;
        align-items: center;
        opacity: 0.5;

        .svg {
          width: rem(16);

          path {
            stroke: $color-white;
          }
        }
      }

      input {
        @include grid-row(1);
        @include grid-column(1, 2);
        padding: rem(20) rem(76) rem(20) rem(30);
        outline: none;

        &:focus {
          & + button {
            opacity: 1;

            path {
              stroke: $color-cyan;
            }
          }
        }
      }

      @include page-break(2) {
        @include grid-template-columns(auto #{rem(70)});

        input {
          padding: rem(26) rem(76) rem(26) rem(45);
        }

        button {
          .svg {
            width: rem(24);
          }
        }
      }

      @include page-break(5) {
        @include grid-template-columns(auto #{rem(70)});

        input {
          padding: rem(21) rem(76) rem(21) rem(35);
        }

        button {
          .svg {
            width: rem(18);
          }
        }
      }
    }
  }

  .section {
    margin-bottom: rem(20);
    font-size: rem(12);

    @include page-break(2) {
      font-size: rem(24);
    }

    @include page-break(5) {
      font-size: rem(14);
    }
  }

  .widget-match {
    .link {
      color: $color-black;

      &::after {
        background: $color-green-medium;
      }

      span {
        transition: 0.2s ease-in-out;
      }

      .no-touch & {
        span {
          opacity: 0.5;
        }
      }
    }

    a {
      display: flex;
      justify-content: flex-start;
      align-items: center;

      .svg-arrow {
        margin-right: rem(10);
        width: rem(14);

        path {
          stroke: $color-green-medium;
          transition: 0.2s ease-in-out;
        }
      }

      @include page-break(2) {
        font-size: rem(26);

        .svg-arrow {
          margin-right: rem(17);
          width: rem(25);
        }
      }

      @include page-break(5) {
        font-size: rem(20);

        .svg-arrow {
          margin-right: rem(10);
          width: rem(14);
        }
      }

      @include hover {
        .svg-arrow {
          path {
            stroke: $color-black;
          }
        }

        .link {
          span {
            opacity: 1;
          }
        }
      }
    }
  }

  .results {
    padding: rem(100) 0;

    h2 {
      margin-bottom: rem(10);
      font-size: rem(16);
      font-weight: 500;

      @include page-break(2) {
        font-size: rem(32);
      }

      @include page-break(5) {
        font-size: rem(20);
      }
    }

    .body {
      margin-bottom: rem(20);
    }
  }
}
