@import "utils/__utils";

.main-nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-items: flex-start;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 3;
  width: 100%;
  padding-top: rem(40);
  padding: rem(37) rem(40);
  transition: all 0.2s ease-in-out;

  button,
  a {
    color: $color-white;
  }

  .svg-user {
    path {
      stroke: $color-cyan;
    }
  }

  .employee-toggle,
  .menu-toggle {
    .text {
      display: none;
    }
  }

  .employee-toggle {
    .svg {
      margin-top: rem(-2);
      width: rem(21);
    }
  }

  .menu-toggle {
    .svg {
      width: rem(23);
    }
  }

  .home {
    width: rem(150);
  }

  .menu-fixed &,
  .page & {
    .svg-user,
    .svg-hamburger {
      path {
        stroke: $color-green-medium;
      }
    }

    .svg-logo {
      path {
        fill: $color-black;
      }
    }
  }

  .menu-fixed & {
    position: fixed;
    top: 0;
    left: 0;
    background: $color-base;
    box-shadow: 0px 1px 23px 1px rgba(0, 0, 0, 0.75);
    transition: all 0.3s ease-in-out;
  }

  .menu-hidden & {
    box-shadow: none;
    transform: translateY(-100%);
  }

  @include page-break(1) {
    .employee-toggle,
    .menu-toggle {
      & > span {
        display: flex;
        justify-content: flex-start;
        align-items: center;
      }

      .text {
        display: block;
        font-size: rem(18);
        white-space: nowrap;
        opacity: 0.9;
        transition: opacity 0.2s ease-in-out;

        @include hover {
          opacity: 1;
        }
      }
    }

    .employee-toggle {
      .svg {
        margin-right: 1em;
      }
    }

    .menu-toggle {
      .svg {
        margin-left: 1em;
      }
    }

    .menu-fixed &,
    .page & {
      .employee-toggle,
      .menu-toggle {
        .text {
          color: $color-black;
          opacity: 0.6;
        }
      }
    }
  }

  @include page-break(2) {
    // $scale: 1 / 0.58;
    $scale: 1.4;
    padding: rem(37  * $scale) rem(140);

    .employee-toggle {
      .svg {
        margin-top: rem(-2 * $scale);
        width: rem(21 * $scale);
      }
    }

    .menu-toggle {
      .svg {
        width: rem(23 * $scale);
      }
    }

    .home {
      width: rem(150 * $scale);
    }

    .employee-toggle,
    .menu-toggle {
      .text {
        font-size: rem(18 * $scale);
      }
    }
  }

  @include page-break(5) {
    padding: rem(37) rem(140);

    .employee-toggle {
      .svg {
        margin-top: rem(-2);
        width: rem(21);
      }
    }

    .menu-toggle {
      .svg {
        width: rem(23);
      }
    }

    .home {
      width: rem(150);
    }

    .employee-toggle,
    .menu-toggle {
      .text {
        font-size: rem(18);
      }
    }
  }
}

.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 0;
  width: 100%;
  height: 100%;
  background: $color-black;
  opacity: 0;
  transition: opacity 0.4s ease-in-out, z-index 0.4s step-end;

  .slide-open & {
    transition: opacity 0.4s ease-in-out, z-index 0.4s step-start;
    opacity: 0.2;
    z-index: 3;
  }
}
