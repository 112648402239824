.homepage {
  .homepage-header {
    margin-bottom: rem(60);

    @include page-break(2) {
      margin-bottom: rem(110);
    }

    .hint {
      .text {
        display: none;
      }
    }
  }

  .reference-carousel {
    header {
      h2 {
        font-size: rem(40);

        @include page-break(0.5) {
          font-size: rem(50);
        }

        @include page-break(2) {
          font-size: rem(70);
        }
      }
    }
  }

  .reference-carousel {
    background: transparent
      linear-gradient(240deg, #077c74 0%, #266f95 0%, #17b2a6 100%) 0% 0%
      no-repeat padding-box;
  }
}
