@use "sass:math";

.widget-person {
  .image {
    @include grid-row(1);
    @include grid-column(1, 23);

    & > div {
      box-sizing: border-box;
      padding-top: math.div(300%, 2.6);
      height: 100%;
    }
  }

  .text {
    @include grid-row(2);
    @include grid-column(3, 19);
    padding: rem(30) 0;
  }

  .card {
    font-size: rem(12);
    background: $color-blue-dark;

    h3 {
      margin-bottom: 0.5em;
      font-weight: normal;
      font-size: rem(16);
      line-height: math.div(20, 16);
      color: $color-white;
    }

    .role {
      color: $color-cyan;
    }

    .company {
      margin-bottom: rem(10);
      color: $color-blue-light;

      @include page-break(0.5) {
        margin-bottom: 1em;
      }
    }

    .phone,
    .email {
      color: $color-white;
    }

    .phone {
      display: inline-block;
      margin-bottom: rem(10);

      .touch & {
        &::after {
          display: none;
        }
      }

      @include page-break(0.5) {
        margin-bottom: rem(4);
      }
    }
  }

  @include page-break(0.25) {
    .image {
      @include grid-column(1, 11);
      @include grid-row-align(stretch);
    }

    .image,
    .text {
      @include grid-row(1);
    }

    .text {
      @include grid-column(12, 12);
      @include grid-row-align(center);
      padding-left: rem(20);
      padding-right: rem(20);
    }
  }

  @include page-break(0.5) {
    .image {
      @include grid-column(1, 9);
    }

    .text {
      @include grid-column(10, 10);

    }

    .card {
      font-size: rem(14);

      h3 {
        font-size: rem(25);
        max-width: rem(200);
      }

      .phone,
      .email {
        opacity: 0.6;
        transition: opacity 0.2s ease-in-out;

        @include hover(true) {
          opacity: 1;
        }
      }
    }
  }

  @include page-break(1) {
    .image {
      @include grid-column(1, 5);
    }

    .text {
      @include grid-column(6, 10);
      padding-left: rem(60);
      padding-right: rem(60);
    }
  }

  @include page-break(2) {
    .card {
      font-size: rem(22);

      h3 {
        font-size: rem(30);
      }
    }

    .image {
      @include grid-column(1, 3);
    }

    .text {
      @include grid-column(4, 6);
    }
  }

  @include page-break(5) {
    .card {
      font-size: rem(14);

      h3 {
        font-size: rem(25);
      }
    }
  }
}
