@use "sass:math";

service-menu-component {
  .svg {
    display: none;
  }
}

.service-menu,
.services-block {
  .svg {
    display: block;
    margin-right: rem(20);
    width: rem(25);
  }

  .item {
    display: flex;
    justify-content: center;
    justify-content: flex-start;
    align-items: center;
    list-style: none;
    font-size: rem(16);
    font-weight: 600;
  }

  @include page-break(1) {
    .svg {
      width: rem(28);
    }

    .title {
      opacity: 0.6;
      transition: all 0.2s ease-in-out;
    }

    .item {
      font-size: rem(18);
      color: $color-base;

      @include hover {
        .title {
          opacity: 1;
        }
      }
    }

    .active {
      .svg-service-icon {
        @include service-icon($color-base, $color-cyan);
      }

      .title {
        opacity: 1;
      }
    }
  }

  @include page-break(2) {
    .svg {
      width: rem(30);
    }

    .item {
      font-size: rem(20);
    }
  }

  @include page-break(4) {
    .svg {
      width: rem(40);
    }

    .item {
      font-size: rem(25);
    }
  }

  @include page-break(2) {
    .svg {
      width: rem(50);
    }

    .item {
      font-size: rem(30);
    }
  }
}

.services-block {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;

  .item {
    margin: 0 0.6em 1em;
  }
}

.service-menu {
  .chevron {
    stroke: $color-cyan;
  }

  .field-row {
    label {
      display: block;
      margin-bottom: rem(20);
      font-size: rem(14);
      opacity: 0.8;
    }
  }

  .services-select {
    z-index: 1;
    margin: 0 auto;
    max-width: rem(400);

    .item {
      .svg {
        margin-right: rem(21);
        width: rem(21);
      }
    }
  }

  .services__control {
    border: 0;
    border-radius: 0;
    padding: rem(20) rem(28);
    min-height: 0;
    color: $color-white;
    background: #05212c;

    .svg-service-icon {
      @include service-icon($color-base, $color-cyan);
    }

    .item {
      color: $color-white;
    }
  }

  .services__value-container {
    padding: 0;
  }

  .services__indicator-separator {
    display: none;
  }

  .services__menu-list {
    .svg-service-icon {
      @include service-icon($color-black, $color-black);
    }

    .item {
      color: $color-black;
    }

    .services__option {
      .item {
        font-weight: 400;

        .svg {
          margin-right: rem(12);
        }
      }
    }

    .services__option--is-focused {
      background-color: rgba($color-blue-medium, 0.2);
    }

    .services__option--is-selected {
      background-color: $color-blue-medium;

      .item {
        color: $color-white;
      }

      .svg-service-icon {
        @include service-icon($color-white, $color-white);
      }
    }
  }
}

.widget-services {
  padding: rem(60) 0 rem(76);
  text-align: center;
  color: $color-white;
  background: transparent linear-gradient(154deg, #1d6e8e 0%, #002a3b 100%) 0%
    0% no-repeat padding-box;

  h2,
  .text {
    @include grid-column-align(center);
    max-width: rem(270);
  }

  h2 {
    margin-bottom: math.div(20em, 50);
    font-size: rem(50);
    color: $color-blue-light;
  }

  .text {
    margin: 0 auto;
    font-size: rem(16);
    opacity: 0.8;

    p {
      font-size: 1em;
    }
  }

  .list {
    @include grid-column-align(center);
    padding-top: rem(40);

    a {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      margin-bottom: rem(20);
      font-size: rem(20);
      font-weight: 600;
      color: $color-white;

      .svg {
        margin-right: rem(20);
        min-width: rem(30);
      }

      .title {
        opacity: 0.6;
        transition: all 0.2s ease-in-out;
      }

      @include hover {
        .title {
          opacity: 1;
        }
      }
    }

    .active {
      .svg-service-icon {
        @include service-icon($color-base, $color-cyan);
      }

      .title {
        opacity: 1;
      }
    }
  }

  @include page-break(1) {
    padding: rem(100) 0 rem(80);

    h2,
    .text {
      max-width: rem(680);
    }

    h2 {
      font-size: rem(70);
    }

    .list {
      @include grid-column-align(flex-start);
      padding-top: rem(100);
      padding-top: rem(60);

      a {
        margin-left: rem(10);
        margin-right: rem(10);
      }

      & > div {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: flex-start;
      }
    }
  }

  @include page-break(2) {
    padding: rem(126) 0 rem(200);

    h2 {
      font-size: rem(70);
    }

    .text {
      font-size: rem(25);
    }

    .list {
      padding-top: rem(100);

      a {
        margin: 0 rem(20) rem(30);

        font-size: rem(40);

        .svg {
          min-width: rem(40);
        }
      }
    }
  }

  @include page-break(3) {
    .list {
      a {
        font-size: rem(50);

        .svg {
          min-width: rem(50);
        }
      }
    }
  }
}
