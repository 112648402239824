@use "sass:math";

.vacancies {
  padding: rem(60) 0 rem(130);
  background: $color-gray;

  header {
    margin-bottom: rem(40);

    h2,
    label,
    .filter {
      @include grid-row-align(center);
    }

    h2,
    .filter {
      @include full-grid-columns(1, 12);
    }

    h2 {
      @include grid-row(1);
      @include full-grid-columns(1, 12);
      margin-bottom: math.div(40em, 30);
    }

    label {
      @include grid-row(2);
      @include full-grid-columns(1, 2);
    }

    .filter {
      @include grid-row(2);
      @include full-grid-columns(3, 10);
      background-color: $color-blue-medium;
    }
  }

  @include page-break(0.75) {
    header {
      h2,
      label,
      .filter {
        @include grid-row(1);
      }

      h2 {
        margin-bottom: 0;
        @include full-grid-columns(1, 5);
      }

      label {
        @include full-grid-columns(6, 2);
        text-align: right;
      }

      .filter {
        @include full-grid-columns(8, 5);
      }
    }
  }

  @include page-break(2) {
    padding: rem(160) 0 rem(190);

    header {
      margin-bottom: rem(70);

      h2 {
        @include full-grid-columns(1, 9);
      }

      label {
        @include full-grid-columns(10, 1);
        font-size: rem(20);
      }

      .filter {
        @include full-grid-columns(11, 2);
      }
    }
  }
}

.no-vacancies {
  padding-bottom: rem(60);

  header h2,
  p {
    @include full-grid-columns(1, 12);
    @include grid-column-align(center);
  }

  @include page-break(2) {
    padding-bottom: rem(160);
  }
}

.vacancies-entry {
  .page-header {
    hint-component {
      button {
        .svg {
          animation: none;
        }
      }
    }
  }

  .blocks {
    padding-bottom: rem(100);

    @include page-break(2) {
      padding-bottom: rem(160);
    }
  }

  .contacts {
    h2 {
      margin-bottom: rem(20);
    }
  }
}
