.page-entry {
  .main-nav {
    button,
    a {
      color: $color-black;
    }

    .svg-user {
      path {
        stroke: $color-green-medium;
      }
    }

    .svg-hamburger {
      .a {
        stroke: $color-green-medium;
      }
    }

    .employee-toggle,
    .menu-toggle {
      .text {
        opacity: 0.6;
      }
    }
  }

  .page-header {
    .hint {
      .text {
        display: none;
      }
    }
  }

  .widget-entry {
    background: transparent linear-gradient(180deg, #114c64 0%, #002a3b 100%) 0%
      0% no-repeat padding-box;

    &::before {
      background: transparent
        linear-gradient(238deg, #a3ecf2 0%, #1a7e9400 100%) 0% 0% no-repeat
        padding-box;
    }

    .text {
      color: $color-white;
    }

    .link {
      color: $color-white;

      &:before {
        background-image: url(~gfx/arrow-right-cyan.svg);
      }

      &::after {
        background-color: $color-cyan;
      }
    }
  }
}

.vacancies-entry {
  .page-header {
    .hint {
      .text {
        display: block;
      }
    }
  }
}
