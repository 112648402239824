.widget-badges {
  padding-top: rem(60);

  &:first-child {
    padding-top: 0;
  }

  h2 {
    margin-bottom: rem(20);
  }

  .badge {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: rem(8);
    padding: rem(40);
    border: 1px solid rgba(0,0,0,0.33);
    width: calc(50% - #{rem(4)});

    &:nth-child(odd) {
      margin-right: rem(8);
    }

    @include page-break(1) {
      margin-bottom: rem(20);
      width: calc(50% - #{rem(10)});

      &:nth-child(odd) {
        margin-right: rem(20);
      }
    }
  }

  .landscape {
    img {
      max-width: rem(150);
    }
  }

  .portrait {
    img {
      height: rem(40);
      width: auto;

      @include page-break(0.5) {
        height: rem(50);
      }

      @include page-break(2) {
        height: rem(60);
      }
    }
  }

  .list {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: stretch;
  }
}
