@media print {
  .to-main-content,
  main-nav-component,
  hint-component,
  service-contact-component,
  map-component,
  service-team-component,
  .widget-gallery,
  .service-team .filter,
  canvas,
  .service-entry .service-header,
  .svg-service-icon,
  .reference-carousel,
  .widget-vimeo,
  .breadcrumbs,
  .employees .filter,
  .map-container,
  .widget-entry,
  .contact-map header,
  .expandable > button .svg-exit,
  .vacancies header label,
  .vacancies header .filter,
  .vacancies .position .plink a,
  .search-index .section-header form,
  .search-index .widget-match a,
  .widget-image-group .decor,
  .employee-overlay,
  .submenu,
  .backdrop,
  .main-footer {
    display: none !important;
  }

  .widget-location,
  .project-details,
  .widget-image-group,
  figure,
  .image,
  .widget,
  img {
    break-inside: avoid;
  }

  .section-header,
  .page-header {
    display: block;
    height: auto;
    min-height: auto;
    max-height: none;
    text-align: left;
    color: $color-black;
    background: transparent;
  }

  .section-header h1 {
    color: $color-black;
  }

  .section-header .intro {
    opacity: 1;
  }

  .service-article-header > div {
    text-align: left;
  }

  .widget-location .background {
    background: $color-blue-dark;
  }

  .employee-index main header {
    margin-bottom: 0;
  }

  .expandable {
    & > button {
      color: $color-black !important;
      background: transparent !important;

      & > .grid-content-12 {
        display: block;
        background: transparent !important;
      }
    }

    > .content {
      background: transparent !important;
    }
  }

  .about-entry .history .blocks .grid {
    display: block;
  }

  .about-entry .contact-map .company {
    background: transparent !important;
  }
}
