@use "sass:math";

.service-article-header {
  h2,
  .h2 {
    margin-bottom: math.div(20em, 30);
  }

  .intro {
    margin-bottom: rem(98);
  }

  & > div {
    padding: rem(35) 0 rem(30);
    text-align: center;
  }

  .svg-service-icon {
    margin: 0 auto rem(52);
    width: 30%;
    max-width: rem(80);
  }

  hint-component {
    display: block;
    margin-right: rem(20);

    &:last-child {
      margin-right: 0;
    }
  }

  .hints {
    display: flex;
    justify-content: center;
    align-items: center;

    .svg-arrow-down {
      path {
        stroke: $color-green-medium;
      }
    }

    .text {
      @include link;
      padding-bottom: 0.2em;
      font-size: rem(10);
      color: $color-black;

      &:after {
        background: $color-green-medium;
      }

      @include page-break(0.25) {
        font-size: rem(12);
      }

      @include page-break(0.5) {
        font-size: rem(16);
      }
    }

    .hint {
      @include hover {
        .text {
          &:after {
            width: 100%;
          }
        }
      }
    }
  }

  @include page-break(0.5) {
    .svg-service-icon {
      max-width: rem(100);
    }
  }

  @include page-break(2) {
    & > div {
      padding: rem(70) 0;
    }

    .svg-service-icon {
      margin-bottom: rem(55);
      max-width: rem(120);
    }

    .hints {
      .text {
        font-size: rem(math.div(16, 0.75));
      }
    }
  }

  @include page-break(5) {
    .hints {
      .text {
        font-size: rem(16);
      }
    }
  }
}

.service-entry {
  service-contact-component {
    @include page-break(1) {
      margin-bottom: rem(100);
    }

    @include page-break(2) {
      margin-bottom: rem(140);
    }
  }
}
