.widget-blockquote {
  p {
    line-height: normal;
  }

  .cite {
    @include full-grid-columns(2, 10);
    padding: 1.5em 0;
    font-size: rem(12);

    .role,
    .source {
      display: block;
      line-height: 1.2em;
    }

    .source {
      font-weight: bold;
    }

    .role {
      margin-top: 0.5em;
      font-style: italic;
      opacity: 0.8;
    }
  }

  blockquote {
    @include full-grid-columns(1, 12);
  }

  @include page-break(0.5) {
    .cite {
      @include full-grid-columns(2, 5);
      font-size: rem(14);
    }

    blockquote {
      @include full-grid-columns(1, 8);
    }
  }

  @include page-break(2) {
    .cite {
      font-size: rem(22);
    }
  }

  @include page-break(4) {
    .cite {
      @include full-grid-columns(4, 4);
    }

    blockquote {
      @include full-grid-columns(3, 6);
    }
  }

  @include page-break(5) {
    .cite {
      font-size: rem(16);
    }
  }
}
